<template>
  <div class="section-container">
    <HeaderComponent />

    <section class="section-start animate__animated animate__fadeIn">
      <h1 class="heading-primary">Inicio</h1>

      <div class="menu-options animate__animated animate__headShake">
        <div class="main-menu">
          <StartMenuOptionComponent
            v-for="menuOption in menuOptions"
            :key="menuOption.optionName"
            :menuOption="menuOption"
          />
        </div>
        <div class="logout-mobile" @click="logout">
          <img
            class="logout-icon-mobile"
            src="../assets/logout-icon-mobile.svg"
            alt="Logout Icon"
          />
          <span>Cerrar</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import StartMenuOptionComponent from "@/components/StartMenuOptionComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: "StartView",
  components: { StartMenuOptionComponent, HeaderComponent },
  data() {
    return {
      menuOptions: [
        {
          optionName: "Semillas",
          optionImage: require("@/assets/start/menu-option-seed.svg"),
          optionView: "SeedsView",
        },
        {
          optionName: "Frascos",
          optionImage: require("@/assets/start/menu-option-seed-container.svg"),
          optionView: "JarsView",
        },
        {
          optionName: "Caducidad",
          optionImage: require("@/assets/start/menu-option-expirations.svg"),
          optionView: "ExpirationsView",
        },
        {
          optionName: "Historial",
          optionImage: require("@/assets/start/menu-option-history.svg"),
          optionView: "HistoryView",
        },

        // Removed User module from menu, could be restored in the future
        // {
        //   optionName: "Usuario",
        //   optionImage: require("@/assets/start/menu-option-user.svg"),
        //   optionView: "UserView",
        // },
      ],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
    },
  },
};
</script>

<style scoped>
.section-container {
  height: 100vh;
  background-image: url(../assets/start-background.png);
  background-size: cover;
}

.main-menu {
  display: flex;
  gap: 10rem;
  justify-content: center;
  margin-top: 12rem;
}

.menu-options {
  min-height: 55rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5rem;
}

.logout-mobile {
  display: none;
}

@media (max-width: 59em) {
  .main-menu {
    flex-direction: column;
    align-items: center;
    gap: 3.6rem;
    margin-top: 5rem;
  }

  .heading-primary {
    font-size: 3.6rem;
  }

  .logout-icon-mobile {
    width: 3.2rem;
  }

  .logout-mobile {
    font-size: 1.8rem;
    font-weight: 700;
    color: #777;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    margin-bottom: 3.2rem;
  }
}
</style>
