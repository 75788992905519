import { setHeaderToken, removeHeaderToken } from "@/services/DBService";
import UserService from "@/services/UserService";

export const namespaced = true;

export const state = {
  userData: null,
};

export const mutations = {
  SET_USER_DATA(state, userData) {
    state.userData = userData;
    localStorage.setItem("userData", JSON.stringify(userData));
    setHeaderToken(userData.token);
  },
  LOGOUT() {
    localStorage.removeItem("userData");
    location.reload();
  },
  LOGOUT_INTERCEPTOR(state) {
    state.userData = null;
    localStorage.removeItem("userData");
    removeHeaderToken();
  },
};

export const actions = {
  async signup({ commit }, newUser) {
    const userDB = await UserService.signup(newUser);
    commit("SET_USER_DATA", userDB.data.data);
  },
  async login({ commit }, credentials) {
    const userDB = await UserService.login(credentials);
    commit("SET_USER_DATA", userDB.data.data);
  },
  logout({ commit }) {
    commit("LOGOUT");
  },
  logoutInterceptor({ commit }) {
    commit("LOGOUT_INTERCEPTOR");
  },
  setUserData({ commit }, userData) {
    commit("SET_USER_DATA", userData);
  },
};

export const getters = {
  loggedIn(state) {
    return !!state.userData;
  },
};
