import { createApp } from "vue";
import App from "./App.vue";
import loading from "vue3-loading-screen";
import router from "./router/router";
import store from "./store/store";
import Utils from "./features/Utils";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

createApp(App)
  .use(loading, {
    bg: "#41b883ad",
    slot: Utils.getLoadingSpinner(),
  })
  .use(store)
  .use(router)
  .use(VueSweetalert2)
  .mount("#app");
