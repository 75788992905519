<template>
  <div class="input-field">
    <span class="input-field-label">{{ label }}</span>
    <div class="image-buttons" v-if="!disabled">
      <input
        type="file"
        accept="image/png, image/jpeg"
        @change="previewImage"
        class="custom-file-upload"
        :id="uuid"
        :ref="`imageInput-${uuid}`"
      />
      <label :for="uuid" class="custom-file-upload">
        <img src="@/assets/icon-search-image.svg" />
        <span>{{
          ` ${getImageSource() ? "Reemplazar" : "Seleccionar"} imagen`
        }}</span>
      </label>

      <button
        v-if="getImageSource()"
        class="btn btn-delete-img"
        type="button"
        @click="deleteImage"
        title="Eliminar"
      >
        <img src="@/assets/buttons/btn-delete-small.svg" />
      </button>
    </div>

    <div v-if="getImageSource()" class="image-container">
      <img
        class="image"
        :src="getImageSource()"
        alt="Photo"
        @click="toggleModal"
      />
    </div>

    <!-- The Modal -->
    <div
      class="modal animate__animated animate__fadeIn animate__faster"
      v-if="showModal"
    >
      <span class="close" @click="toggleModal">&times;</span>
      <img class="modal-content" :src="getImageSource()" />
    </div>
  </div>
</template>

<script>
import UniqueID from "@/features/UniqueID";

export default {
  setup() {
    const uuid = UniqueID();
    return {
      uuid,
    };
  },
  data() {
    return {
      preview: null,
      image: null,
      showModal: false,
    };
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    source: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    previewImage: function (event) {
      const input = event.target;
      if (input.files.length > 0) {
        const allowedTypes = ["image/jpeg", "image/png"];
        const reader = new FileReader();

        reader.onload = (e) => {
          const imageType = reader.result.split(";")[0].split(":")[1];

          if (!allowedTypes.includes(imageType)) {
            this.$swal({
              title: "¡Error!",
              text: "Debe seleccionar una imagen en formato jpg o png",
              icon: "error",
              confirmButtonColor: "#2eb086",
            });

            event.target.value = "";
          } else {
            this.preview = e.target.result;
            this.image = input.files[0];
            this.$emit("onSelectImage", this.image);
          }
        };

        reader.readAsDataURL(input.files[0]);
      }
    },
    getImageSource() {
      if (this.preview) {
        return this.preview;
      } else if (this.source) {
        return this.source;
      } else {
        return "";
      }
    },
    async deleteImage() {
      const result = await this.$swal({
        title: "¿Está seguro?",
        text: "Una vez borrados los datos no se pueden recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff8787",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        this.preview = null;
        this.image = null;
        if (this.$refs[`imageInput-${this.uuid}`]) {
          this.$refs[`imageInput-${this.uuid}`].value = null;
        }
        this.$emit("onSelectImage", null);
        this.$emit("onDeleteImage");
      }
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.custom-file-upload {
  display: flex;
  width: fit-content;
  cursor: pointer;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
  background-color: #2eb086;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 0.6rem 1.2rem;
  border-radius: 6px;
  align-items: center;
  gap: 7px;
  transition: all 0.3s;
}

.custom-file-upload:hover {
  background-color: #268f6e;
}

.image {
  width: 34.5rem;
  height: 34.5rem;
  object-fit: cover;
  border-radius: 9px;
  transition: all 0.3s;
}

.image-container {
  border: 1px solid #cdcdcd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 9px;
  width: fit-content;
  padding: 0.1rem;
  overflow: hidden;
}

.image-container .image:hover {
  transform: scale(1.1);
  opacity: 0.7;
  cursor: pointer;
}

.image-buttons {
  display: flex;
  gap: 7px;
  justify-content: space-between;
}

.btn-delete-img {
  background-color: #ff8787;
  padding: 0.6rem 0.8rem;
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 7px;
  border-radius: 6px;
}

.btn-delete-img:hover {
  transform: translateY(0);
  background: #e46e6e;
}

/* The Modal (background) */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 90%;
  max-width: 100rem;
  border: 1px solid #333;
}

/* The Close Button */
.close {
  position: absolute;
  top: 1.5rem;
  right: 3.5rem;
  color: #f1f1f1;
  font-size: 6rem;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .image {
    width: 30rem;
    height: 30rem;
  }
}
</style>
