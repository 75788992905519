<template>
  <HeaderComponent showMenuOptions />

  <section class="section-jars animate__animated animate__fadeIn">
    <h1 class="heading-primary">Frascos</h1>

    <div class="table-container">
      <div class="table-menu">
        <div class="table-search-box">
          <label>Buscar:</label>
          <input class="input-field-box" v-model="searchTerm" />
        </div>

        <router-link class="btn btn-main btn--add" :to="{ name: 'AddJarView' }">
          + Agregar Frasco
        </router-link>
      </div>

      <div class="table-menu-mobile">
        <div class="table-search-box">
          <input
            class="input-field-box"
            placeholder="Buscar..."
            v-model="searchTerm"
          />
        </div>

        <router-link class="btn btn-main btn--add" :to="{ name: 'AddJarView' }">
          + Agregar
        </router-link>
      </div>

      <VueTableLite
        :is-static-mode="true"
        :is-loading="table.isLoading"
        :columns="table.columns"
        :rows="table.rows"
        :total="table.totalRecordCount"
        :sortable="table.sortable"
        :messages="table.messages"
        @row-clicked="rowClicked"
      ></VueTableLite>
    </div>
  </section>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import { reactive, ref, computed } from "vue";
import VueTableLite from "vue3-table-lite";
import { useStore } from "vuex";
import router from "@/router/router";

export default {
  name: "JarsView",
  components: {
    HeaderComponent,
    VueTableLite,
  },
  setup() {
    const store = useStore();

    const searchTerm = ref(store.state.jar.searchJarFilter); // Search text
    const data = reactive({
      rows: [],
    });

    /**
     * Get server data request
     */
    const myRequest = async () => {
      table.isLoading = true;
      try {
        const jars = await store.dispatch("jar/fetchJars");
        table.isLoading = false;
        return jars;
      } catch (err) {
        console.error(err);
      }
    };

    // Table config
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Código",
          field: "code",
          width: "10%",
          sortable: true,
          // isKey: true,
          display: function (row) {
            if (row.code) {
              return row.code;
            } else {
              return "-";
            }
          },
        },
        {
          label: "Semilla",
          field: "seed",
          width: "10%",
          sortable: true,
          display: function (row) {
            if (row.seed) {
              return (
                row.seed.variety.charAt(0).toUpperCase() +
                row.seed.variety.slice(1)
              );
            }
          },
        },
        {
          label: "Procedencia",
          field: "origin",
          width: "10%",
          sortable: true,
          display: function (row) {
            if (row.origin) {
              return row.origin;
            } else {
              return "N/A";
            }
          },
        },
        {
          label: "Cantidad (en gramos)",
          field: "quantity",
          width: "10%",
          sortable: true,
          display: function (row) {
            if (row.quantity) {
              return `${row.quantity} gr.`;
            } else {
              return "N/A";
            }
          },
        },
        {
          label: "Fecha de Cosecha",
          field: "harvestDate",
          width: "10%",
          sortable: true,
          display: function (row) {
            if (row.harvestDate) {
              return new Date(row.harvestDate).toLocaleDateString("es-CO", {
                timeZone: "UTC",
              });
            } else {
              return "N/A";
            }
          },
        },
        {
          label: "Fecha de Caducidad",
          field: "expirationDate",
          width: "10%",
          sortable: true,
          display: function (row) {
            if (row.expirationDate) {
              return new Date(row.expirationDate).toLocaleDateString("es-CO", {
                timeZone: "UTC",
              });
            } else {
              return "N/A";
            }
          },
        },
        // Disabled germination percentages field in table for now
        // This field could be added again to the table in the future

        // {
        //   label: "Porcentaje de Germinación",
        //   field: "germinationPercentages",
        //   width: "10%",
        //   sortable: false,
        //   display: function (row) {
        //     if (row.germinationPercentages) {
        //       return `${row.germinationPercentages} %`;
        //     } else {
        //       return "N/A";
        //     }
        //   },
        // },
      ],
      rows: computed(() => {
        if (!data.rows) {
          return [];
        }
        // Filter by seed name and by code name
        return data.rows.filter(
          (jar) =>
            jar.seed !== null &&
            (jar.seed.variety
              .toLowerCase()
              .includes(searchTerm.value.toLowerCase()) ||
              (jar.code &&
                jar.code
                  .toLowerCase()
                  .includes(searchTerm.value.toLowerCase())))
        );
      }),
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      sortable: {
        order: "id",
        sort: "asc",
      },
      messages: {
        pagingInfo: "Mostrando del {0} al {1} de {2} registros",
        pageSizeChangeLabel: "Mostrar:",
        gotoPageLabel: "Página:",
        noDataAvailable: "Sin datos",
      },
    });
    // Get data on first rendering
    myRequest().then((newData) => {
      data.rows = newData;
    });

    const rowClicked = (row) => {
      router.push({
        name: "EditJar",
        params: { id: row._id },
      });
    };
    return {
      searchTerm,
      table,
      rowClicked,
    };
  },
  beforeUnmount() {
    this.$store.dispatch("jar/saveSearchJarFilter", this.searchTerm);
  },
};
</script>

<style scoped>
.section-jars {
  margin-bottom: 3.2rem;
  min-height: 60vh;
}
</style>
