<template>
  <HeaderComponent showMenuOptions seeds />

  <section class="section-add-seed">
    <div class="steps-nav">
      <AddSeedNavComponentVue />
    </div>

    <h1 class="heading-primary">Cargar Semilla</h1>

    <form
      autocomplete="off"
      @submit.prevent="addNewSeed"
      class="form-input-fields animate__animated animate__fadeIn animate__fast"
    >
      <BaseInput
        label="Origen"
        type="text"
        v-model="newSeed.origin"
        tooltip="¿De dónde proviene la semilla? Zona geográfica, 
                comunidad, historia, u otra información de su 
                orígen preservada por la comunidad"
      />
      <BaseInput
        label="Período de almacenamiento (en meses)"
        type="number"
        v-model="newSeed.storagePeriod"
      />

      <ImageComponent
        label="Foto de semilla"
        @onSelectImage="(image) => (this.seedPhoto = image)"
      />

      <ImageComponent
        label="Foto de planta"
        @onSelectImage="(image) => (this.plantPhoto = image)"
      />

      <div class="add-input-field">
        <button
          class="btn btn-main btn--add-small"
          type="button"
          :onClick="addNewProperty"
        >
          +
        </button>

        <div class="input-field">
          <label class="input-field-label">Propiedades</label>

          <VueMultiselect
            v-model="newSeed.properties"
            :options="properties"
            :multiple="true"
            :close-on-select="true"
            placeholder="Seleccionar"
            label="name"
            track-by="name"
          />
        </div>
      </div>

      <div class="add-input-field">
        <button
          class="btn btn-main btn--add-small"
          type="button"
          :onClick="addNewTypicalUse"
        >
          +
        </button>
        <div class="input-field">
          <label class="input-field-label">Usos típicos</label>
          <VueMultiselect
            v-model="newSeed.typicalUses"
            :options="typicalUses"
            :multiple="true"
            :close-on-select="true"
            placeholder="Seleccionar"
            label="name"
            track-by="name"
          />
        </div>
      </div>

      <BaseInput
        label="Descripción"
        type="text"
        v-model="newSeed.description"
        tooltip="Otra información relevante"
      />

      <div class="page-nav-buttons">
        <router-link class="btn btn-secondary" :to="{ name: 'AddSeedStep2' }">
          Volver
        </router-link>
        <button class="btn btn-main btn--right" type="submit">
          Cargar Semilla
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import AddSeedNavComponentVue from "@/components/AddSeedNavComponent.vue";
import BaseInput from "@/components/BaseInput.vue";
import router from "@/router/router";
import Utils from "@/features/Utils";
import VueMultiselect from "vue-multiselect";
import Swal from "sweetalert2";
import ImageComponent from "@/components/image/ImageComponent.vue";

export default {
  components: {
    HeaderComponent,
    AddSeedNavComponentVue,
    BaseInput,
    VueMultiselect,
    ImageComponent,
  },
  data() {
    return {
      seedPhoto: null,
      plantPhoto: null,
    };
  },
  created() {
    (async () => {
      this.$isLoading(true);
      try {
        await Promise.all([
          this.$store.dispatch("seedDetails/fetchProperties"),
          this.$store.dispatch("seedDetails/fetchTypicalUses"),
        ]);
      } catch (err) {
        console.error(err);
      }
      this.$isLoading(false);
    })();
  },
  computed: {
    newSeed() {
      return this.$store.state.seed.newSeed;
    },
    properties() {
      return this.$store.state.seedDetails.properties;
    },
    typicalUses() {
      return this.$store.state.seedDetails.typicalUses;
    },
  },
  methods: {
    async addNewSeed() {
      this.$isLoading(true);
      try {
        const newSeedData = JSON.parse(JSON.stringify(this.newSeed));
        const createdSeed = await this.$store.dispatch(
          "seed/createSeed",
          newSeedData
        );

        // Upload images if exists
        if (this.seedPhoto || this.plantPhoto) {
          const formData = new FormData();
          formData.append("seedPhoto", this.seedPhoto);
          formData.append("plantPhoto", this.plantPhoto);

          await this.$store.dispatch("seed/uploadImage", {
            id: createdSeed.id,
            formData,
          });
        }

        // Clean add seed steps state
        this.$store.dispatch("seed/resetNewSeedState");

        // Navigate to seeds
        router.push("/seeds");
        Utils.toast.fire({
          icon: "success",
          title: "Semilla cargada exitosamente!",
        });
      } catch (err) {
        this.$swal({
          title: "¡Error!",
          text: err.response.data
            ? err.response.data.message
            : "Error al obtener respuesta de servicio",
          icon: "error",
          confirmButtonColor: "#2eb086",
        });
      }
      this.$isLoading(false);
    },
    async addNewProperty() {
      const { value: propertyName } = await Swal.fire({
        title: "Nombre de propiedad",
        input: "text",
        inputLabel: "Ingrese el nombre de propiedad",
        confirmButtonColor: "#2eb086",
        cancelButtonColor: "#aaa",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Debes colocar un nombre!";
          }
        },
      });

      if (propertyName) {
        const newProperty = {
          name: propertyName,
        };

        this.$isLoading(true);
        try {
          await this.$store.dispatch("seedDetails/createProperty", newProperty);

          const propertyNameCapitalize =
            propertyName.charAt(0).toUpperCase() +
            propertyName.slice(1).toLowerCase();

          this.$swal({
            title: "¡Éxito!",
            text: `Propiedad ${propertyNameCapitalize} agregada correctamente.`,
            icon: "success",
            confirmButtonColor: "#2eb086",
          });
        } catch (err) {
          console.error(err);
          this.$swal({
            title: "¡Error!",
            text: err.response
              ? err.response.data.message
              : "Error al obtener respuesta de servicio",
            icon: "error",
            confirmButtonColor: "#2eb086",
          });
        }
        this.$isLoading(false);
      }
    },
    async addNewTypicalUse() {
      const { value: typicalUseName } = await Swal.fire({
        title: "Nombre de uso típico",
        input: "text",
        inputLabel: "Ingrese el nombre de uso típico",
        confirmButtonColor: "#2eb086",
        cancelButtonColor: "#aaa",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Debes colocar un nombre!";
          }
        },
      });

      if (typicalUseName) {
        const newTypicalUse = {
          name: typicalUseName,
        };

        this.$isLoading(true);
        try {
          await this.$store.dispatch(
            "seedDetails/createTypicalUse",
            newTypicalUse
          );

          const propertyNameCapitalize =
            typicalUseName.charAt(0).toUpperCase() +
            typicalUseName.slice(1).toLowerCase();

          this.$swal({
            title: "¡Éxito!",
            text: `Propiedad ${propertyNameCapitalize} agregada correctamente.`,
            icon: "success",
            confirmButtonColor: "#2eb086",
          });
        } catch (err) {
          console.error(err);
          this.$swal({
            title: "¡Error!",
            text: err.response
              ? err.response.data.message
              : "Error al obtener respuesta de servicio",
            icon: "error",
            confirmButtonColor: "#2eb086",
          });
        }
        this.$isLoading(false);
      }
    },
  },
  beforeUnmount() {
    this.$store.dispatch("seed/saveNewSeedState", this.newSeed);
  },
};
</script>

<style scoped>
.section-add-seed {
  height: auto;
}

.heading-primary {
  margin-top: 4rem;
}
</style>
