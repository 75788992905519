<template>
  <HeaderComponent showMenuOptions seeds />

  <section class="section-add-seed">
    <h1 class="heading-primary">Editar Semilla</h1>

    <form
      autocomplete="off"
      @submit.prevent="editSeed"
      class="form-input-fields"
      v-if="selectedSeed"
    >
      <BaseInput
        label="Variedad"
        type="text"
        v-model="selectedSeed.variety"
        tooltip="Nombre común de la semilla (ej. Maíz Blanco)"
      />

      <BaseInput
        label="Nombre Científico"
        type="text"
        v-model="selectedSeed.scientificName"
        tooltip="Nombre único utilizado por la comunidad científica"
      />

      <BaseSelect
        :options="categories"
        label="Categorías"
        v-model="selectedSeed.categories"
        tooltip="Se pueden seleccionar múltiples categorías"
      />

      <div class="add-input-field">
        <button
          class="btn btn-main btn--add-small"
          type="button"
          :onClick="addNewFamily"
        >
          +
        </button>
        <BaseSelect
          :options="families"
          label="Familia"
          v-model="selectedSeed.family"
          tooltip="Nombre taxonómico que agrupa al género y a la especie"
        />
      </div>

      <div class="range-field">
        <div class="input-field-label-container">
          <span class="input-field-label"
            >Rango altitudinal (mts/nivel del mar)</span
          >
          <TooltipComponent
            tooltipText="Altura medida en metros sobre el nivel del mar donde la planta puede desarrollarse"
          />
        </div>

        <div class="range-inputs">
          <BaseInput
            label="Desde"
            type="number"
            min="0"
            max="10000"
            v-if="selectedSeed.range"
            v-model="selectedSeed.range.from"
          />
          <BaseInput
            label="Hasta"
            type="number"
            min="0"
            max="10000"
            v-if="selectedSeed.range"
            v-model="selectedSeed.range.to"
          />
        </div>
      </div>

      <BaseSelect
        :options="pollination"
        label="Polinización"
        v-model="selectedSeed.pollination"
        tooltip="Autógamas se polinizan a sí mismas (trigo), 
                alógamas se polinizan de manera cruzada (maíz), 
                mixtas es una combinación de ambas (sorgo)"
      />

      <BaseSelect
        :options="cropDuration"
        label="Duración del cultivo"
        v-model="selectedSeed.cropDuration"
      />

      <div class="input-field">
        <div class="input-field-label-container">
          <label class="input-field-label">Forma de crecimiento</label>
          <TooltipComponent
            tooltipText="Relacionado a la morfología de la planta"
          />
        </div>

        <VueMultiselect
          v-model="selectedSeed.growthForm"
          :options="growthForm"
          :multiple="true"
          :close-on-select="true"
          placeholder="Seleccionar"
          label="name"
          track-by="name"
        />
      </div>

      <BaseSelect
        :options="bioIntensiveMethod"
        label="Método biointensivo"
        v-model="selectedSeed.bioIntensiveMethod"
        tooltip="Responde a cómo se relaciona con los nutrientes del suelo"
      />

      <BaseSelect
        :options="germinationMethod"
        label="Método de germinación"
        v-model="selectedSeed.germinationMethod"
      />

      <BaseInput
        label="Tiempo de germinación (en días)"
        type="number"
        v-model="selectedSeed.germinationTime"
      />

      <BaseSelect
        :options="dispersionMethod"
        label="Modo de dispersión"
        v-model="selectedSeed.dispersionMethod"
        tooltip="Mecanismo de dispersión de la semilla: 
                la misma planta (autocoria) el viento (anemocoria), 
                la gravedad (barocoria), agua (hidrocoria), por animal (zoocoria)."
      />

      <BaseInput
        label="Origen"
        type="text"
        v-model="selectedSeed.origin"
        tooltip="¿De dónde proviene la semilla? Zona geográfica, 
                comunidad, historia, u otra información de su 
                orígen preservada por la comunidad"
      />

      <BaseInput
        label="Periodo de almacenamiento (en meses)"
        type="text"
        v-model="selectedSeed.storagePeriod"
      />

      <ImageComponent
        label="Foto de semilla"
        :source="selectedSeed.seedPhoto?.url"
        @onSelectImage="(image) => (this.seedPhoto = image)"
        @onDeleteImage="deleteSeedImage"
      />

      <ImageComponent
        label="Foto de planta"
        :source="selectedSeed.plantPhoto?.url"
        @onSelectImage="(image) => (this.plantPhoto = image)"
        @onDeleteImage="deletePlantImage"
      />

      <div class="add-input-field">
        <button
          class="btn btn-main btn--add-small"
          type="button"
          :onClick="addNewProperty"
        >
          +
        </button>
        <div class="input-field">
          <label class="input-field-label">Propiedades</label>

          <VueMultiselect
            v-model="selectedSeed.properties"
            :options="properties"
            :multiple="true"
            :close-on-select="true"
            placeholder="Seleccionar"
            label="name"
            track-by="name"
          />
        </div>
      </div>

      <div class="add-input-field">
        <button
          class="btn btn-main btn--add-small"
          type="button"
          :onClick="addNewTypicalUse"
        >
          +
        </button>
        <div class="input-field">
          <label class="input-field-label">Usos típicos</label>
          <VueMultiselect
            v-model="selectedSeed.typicalUses"
            :options="typicalUses"
            :multiple="true"
            :close-on-select="true"
            placeholder="Seleccionar"
            label="name"
            track-by="name"
          />
        </div>
      </div>

      <BaseInput
        label="Descripción"
        type="text"
        v-model="selectedSeed.description"
        tooltip="Otra información relevante"
      />

      <div class="page-nav-buttons">
        <router-link class="btn btn-secondary" :to="{ name: 'SeedsView' }">
          Volver
        </router-link>

        <button class="btn btn-delete" type="button" @click="deleteSeed">
          Borrar
        </button>

        <button class="btn btn-main btn--right" type="submit">Guardar</button>
      </div>
    </form>
  </section>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import router from "@/router/router";
import Swal from "sweetalert2";
import VueMultiselect from "vue-multiselect";
import TooltipComponent from "@/components/TooltipComponent.vue";
import ImageComponent from "@/components/image/ImageComponent.vue";

export default {
  data() {
    return {
      seedPhoto: null,
      plantPhoto: null,
    };
  },
  props: ["id"],
  created() {
    (async () => {
      this.$isLoading(true);

      try {
        await Promise.all([
          this.$store.dispatch("seedDetails/fetchCategories"),
          this.$store.dispatch("seedDetails/fetchFamilies"),
          this.$store.dispatch("seedDetails/fetchPollination"),
          this.$store.dispatch("seedDetails/fetchGrowthForm"),
          this.$store.dispatch("seedDetails/fetchGerminationMethod"),
          this.$store.dispatch("seedDetails/fetchDispersionMethod"),
          this.$store.dispatch("seedDetails/fetchCropDuration"),
          this.$store.dispatch("seedDetails/fetchBioIntensiveMethod"),
          this.$store.dispatch("seedDetails/fetchProperties"),
          this.$store.dispatch("seedDetails/fetchTypicalUses"),
          this.$store.dispatch("seed/fetchSeed", this.id),
        ]);
      } catch (err) {
        this.$swal({
          title: "¡Error!",
          text: err.response.data
            ? err.response.data.message
            : "Error al obtener respuesta de servicio",
          icon: "error",
          confirmButtonColor: "#2eb086",
        });
      }
      this.$isLoading(false);
    })();
  },
  components: {
    HeaderComponent,
    BaseInput,
    BaseSelect,
    VueMultiselect,
    TooltipComponent,
    ImageComponent,
  },
  computed: {
    selectedSeed() {
      return this.$store.state.seed.selectedSeed;
    },
    categories() {
      return this.$store.state.seedDetails.categories;
    },
    families() {
      return this.$store.state.seedDetails.families;
    },
    pollination() {
      return this.$store.state.seedDetails.pollination;
    },
    growthForm() {
      return this.$store.state.seedDetails.growthForm;
    },
    germinationMethod() {
      return this.$store.state.seedDetails.germinationMethod;
    },
    dispersionMethod() {
      return this.$store.state.seedDetails.dispersionMethod;
    },
    cropDuration() {
      return this.$store.state.seedDetails.cropDuration;
    },
    bioIntensiveMethod() {
      return this.$store.state.seedDetails.bioIntensiveMethod;
    },
    properties() {
      return this.$store.state.seedDetails.properties;
    },
    typicalUses() {
      return this.$store.state.seedDetails.typicalUses;
    },
  },
  methods: {
    async editSeed() {
      this.$isLoading(true);
      try {
        await this.$store.dispatch("seed/updateSeed", this.id);

        // Upload images if exists
        if (this.seedPhoto || this.plantPhoto) {
          const formData = new FormData();
          formData.append("seedPhoto", this.seedPhoto);
          formData.append("plantPhoto", this.plantPhoto);

          await this.$store.dispatch("seed/uploadImage", {
            id: this.id,
            formData,
          });
        }
        router.push("/seeds");
      } catch (err) {
        console.error(err);
        this.$swal({
          title: "¡Error!",
          text: err.response.data
            ? err.response.data.message
            : "Error al obtener respuesta de servicio",
          icon: "error",
          confirmButtonColor: "#2eb086",
        });
      }
      this.$isLoading(false);
    },
    async deleteSeed() {
      const result = await this.$swal({
        title: "¿Está seguro?",
        text: "Una vez borrados los datos no se pueden recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff8787",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Sí, borrar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        this.$isLoading(true);
        try {
          await this.$store.dispatch("seed/deleteSeed", this.id);
          router.push("/seeds");
          this.$swal({
            title: "¡Borrado!",
            text: "La semilla ha sido borrada.",
            icon: "success",
            confirmButtonColor: "#2eb086",
          });
        } catch (err) {
          this.$swal({
            title: "¡Error!",
            text: err.response.data
              ? err.response.data.message
              : "Error al obtener respuesta de servicio",
            icon: "error",
            confirmButtonColor: "#2eb086",
          });
        }
        this.$isLoading(false);
      }
    },
    async deleteSeedImage() {
      this.$isLoading(true);

      if (this.selectedSeed.seedPhoto) {
        await this.$store.dispatch("seed/deleteImage", "seedPhoto");
      }

      this.$isLoading(false);
    },
    async deletePlantImage() {
      this.$isLoading(true);

      if (this.selectedSeed.plantPhoto) {
        await this.$store.dispatch("seed/deleteImage", "plantPhoto");
      }

      this.$isLoading(false);
    },
    async addNewFamily() {
      const { value: familyName } = await Swal.fire({
        title: "Nombre de familia",
        input: "text",
        inputLabel: "Ingrese el nombre de familia",
        confirmButtonColor: "#2eb086",
        cancelButtonColor: "#aaa",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Debes colocar un nombre!";
          }
        },
      });

      if (familyName) {
        const newFamily = {
          name: familyName,
        };

        this.$isLoading(true);
        try {
          await this.$store.dispatch("seedDetails/createFamily", newFamily);

          const familyNameCapitalize =
            familyName.charAt(0).toUpperCase() +
            familyName.slice(1).toLowerCase();

          this.$swal({
            title: "¡Éxito!",
            text: `Familia ${familyNameCapitalize} agregada correctamente.`,
            icon: "success",
            confirmButtonColor: "#2eb086",
          });
        } catch (err) {
          console.error(err);
          this.$swal({
            title: "¡Error!",
            text: err.response
              ? err.response.data.message
              : "Error al obtener respuesta de servicio",
            icon: "error",
            confirmButtonColor: "#2eb086",
          });
        }
        this.$isLoading(false);
      }
    },
    async addNewProperty() {
      const { value: propertyName } = await Swal.fire({
        title: "Nombre de propiedad",
        input: "text",
        inputLabel: "Ingrese el nombre de propiedad",
        confirmButtonColor: "#2eb086",
        cancelButtonColor: "#aaa",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Debes colocar un nombre!";
          }
        },
      });

      if (propertyName) {
        const newProperty = {
          name: propertyName,
        };

        this.$isLoading(true);
        try {
          await this.$store.dispatch("seedDetails/createProperty", newProperty);

          const propertyNameCapitalize =
            propertyName.charAt(0).toUpperCase() +
            propertyName.slice(1).toLowerCase();

          this.$swal({
            title: "¡Éxito!",
            text: `Propiedad ${propertyNameCapitalize} agregada correctamente.`,
            icon: "success",
            confirmButtonColor: "#2eb086",
          });
        } catch (err) {
          console.error(err);
          this.$swal({
            title: "¡Error!",
            text: err.response
              ? err.response.data.message
              : "Error al obtener respuesta de servicio",
            icon: "error",
            confirmButtonColor: "#2eb086",
          });
        }
        this.$isLoading(false);
      }
    },
    async addNewTypicalUse() {
      const { value: typicalUseName } = await Swal.fire({
        title: "Nombre de uso típico",
        input: "text",
        inputLabel: "Ingrese el nombre de uso típico",
        confirmButtonColor: "#2eb086",
        cancelButtonColor: "#aaa",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Debes colocar un nombre!";
          }
        },
      });

      if (typicalUseName) {
        const newTypicalUse = {
          name: typicalUseName,
        };

        this.$isLoading(true);
        try {
          await this.$store.dispatch(
            "seedDetails/createTypicalUse",
            newTypicalUse
          );

          const propertyNameCapitalize =
            typicalUseName.charAt(0).toUpperCase() +
            typicalUseName.slice(1).toLowerCase();

          this.$swal({
            title: "¡Éxito!",
            text: `Propiedad ${propertyNameCapitalize} agregada correctamente.`,
            icon: "success",
            confirmButtonColor: "#2eb086",
          });
        } catch (err) {
          console.error(err);
          this.$swal({
            title: "¡Error!",
            text: err.response
              ? err.response.data.message
              : "Error al obtener respuesta de servicio",
            icon: "error",
            confirmButtonColor: "#2eb086",
          });
        }
        this.$isLoading(false);
      }
    },
  },
  beforeUnmount() {
    this.$store.dispatch("seed/resetSelectedSeed");
  },
};
</script>

<style scoped>
.section-add-seed {
  height: auto;
}

.range-field {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: auto;
}

.range-inputs {
  display: flex;
  gap: 2.5rem;
}
</style>
