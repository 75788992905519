import SeedService from "@/services/SeedService";

export const namespaced = true;

export const state = {
  newSeed: {
    variety: "",
    scientificName: "",
    categories: null,
    family: null,
    range: {
      from: 0,
      to: 0,
    },
    pollination: null,
    cropDuration: null,
    growthForm: null,
    bioIntensiveMethod: null,
    germinationMethod: null,
    germinationTime: "",
    dispersionMethod: null,
    origin: "",
    storagePeriod: "",
    seedPhoto: null,
    plantPhoto: null,
    properties: null,
    typicalUses: null,
    description: "",
  },
  selectedSeed: null,
  seeds: [],
  fetchedSeeds: false,
};

export const mutations = {
  SET_NEW_SEED(state, newSeed) {
    state.newSeed = newSeed;
  },
  SET_SELECTED_SEED(state, selectedSeed) {
    state.selectedSeed = selectedSeed;
  },
  SET_SEEDS(state, seedsDB) {
    state.seeds = seedsDB;
  },
  RESET_NEW_SEED(state) {
    state.newSeed = {
      variety: "",
      scientificName: "",
      categories: null,
      family: null,
      range: {
        from: 0,
        to: 0,
      },
      pollination: null,
      cropDuration: null,
      growthForm: null,
      bioIntensiveMethod: null,
      germinationMethod: null,
      germinationTime: "",
      dispersionMethod: null,
      origin: "",
      storagePeriod: "",
      seedPhoto: null,
      plantPhoto: null,
      properties: null,
      typicalUses: null,
      description: "",
    };
  },
  ADD_SEED(state, seed) {
    state.seeds.push(seed);
  },
  UPDATE_SEED(state, seedDB) {
    const foundIndex = state.seeds.findIndex((seed) => seed._id === seedDB._id);
    state.seeds[foundIndex] = seedDB;
    if (state.selectedSeed && state.selectedSeed.id === seedDB.id) {
      state.selectedSeed = seedDB;
    }
  },
  DELETE_SEED(state, id) {
    const seedsFiltered = state.seeds.filter((seed) => seed._id !== id);
    state.seeds = seedsFiltered;
  },
  SET_FETCHED_SEEDS(state) {
    state.fetchedSeeds = true;
  },
};

export const actions = {
  saveNewSeedState({ commit }, newSeed) {
    commit("SET_NEW_SEED", newSeed);
  },
  resetNewSeedState({ commit }) {
    commit("RESET_NEW_SEED");
  },
  resetSelectedSeed({ commit }) {
    commit("SET_SELECTED_SEED", null);
  },
  async createSeed({ commit }, seed) {
    const seedDB = await SeedService.postSeed(seed);
    commit("ADD_SEED", seedDB.data.data.seed);
    return seedDB.data.data.seed;
  },
  async fetchSeeds({ commit }) {
    if (state.fetchedSeeds) {
      return state.seeds;
    } else {
      const seedsDB = await SeedService.getSeeds();
      commit("SET_SEEDS", seedsDB.data.data.seeds);
      commit("SET_FETCHED_SEEDS");
      return seedsDB.data.data.seeds;
    }
  },
  async fetchSeed({ commit, state }, id) {
    const selectedSeed = state.seeds.find((seed) => seed._id === id);
    if (selectedSeed) {
      const selectedSeedCopy = JSON.parse(JSON.stringify(selectedSeed));
      commit("SET_SELECTED_SEED", selectedSeedCopy);
    } else {
      const seedDB = await SeedService.getSeed(id);
      commit("SET_SELECTED_SEED", seedDB.data.data.seed);
    }
  },
  async updateSeed({ commit, state }, id) {
    const seedDB = await SeedService.updateSeed(id, state.selectedSeed);
    commit("UPDATE_SEED", seedDB.data.data.seed);
  },
  async deleteSeed({ commit }, id) {
    await SeedService.deleteSeed(id);
    commit("DELETE_SEED", id);
  },
  async uploadImage({ commit }, payload) {
    const seedDB = await SeedService.uploadImage(payload.id, payload.formData);
    commit("UPDATE_SEED", seedDB.data.data.seed);
  },
  async deleteImage({ commit, state }, resource) {
    // Delete image if it is already uploaded, it means it is in the selected seed
    if (state.selectedSeed) {
      const seedDB = await SeedService.deleteImage(
        state.selectedSeed.id,
        resource
      );
      commit("UPDATE_SEED", seedDB.data.data.seed);
    }
  },
};
