<template>
  <HeaderComponent showMenuOptions />

  <section class="section-jars animate__animated animate__fadeIn">
    <h1 class="heading-primary">Historial</h1>

    <div class="table-container">
      <div class="table-menu">
        <div class="table-search-box">
          <label>Buscar:</label>
          <input class="input-field-box" v-model="searchTerm" />
        </div>
      </div>

      <div class="table-menu-mobile">
        <div class="table-search-box">
          <input
            class="input-field-box"
            placeholder="Buscar..."
            v-model="searchTerm"
          />
        </div>
      </div>

      <VueTableLite
        :is-static-mode="true"
        :is-loading="table.isLoading"
        :columns="table.columns"
        :rows="table.rows"
        :total="table.totalRecordCount"
        :sortable="table.sortable"
        :messages="table.messages"
        @row-clicked="rowClicked"
      ></VueTableLite>
    </div>
  </section>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import { reactive, ref, computed } from "vue";
import VueTableLite from "vue3-table-lite";
import { useStore } from "vuex";
import router from "@/router/router";

export default {
  name: "HistoryView",
  components: {
    HeaderComponent,
    VueTableLite,
  },
  setup() {
    const store = useStore();

    const searchTerm = ref(""); // Search text
    const data = reactive({
      rows: [],
    });

    /**
     * Get server data request
     */
    const myRequest = async () => {
      table.isLoading = true;
      try {
        const jarsHistory = await store.dispatch("jar/fetchJarsHistory");
        table.isLoading = false;
        return jarsHistory;
      } catch (err) {
        console.error(err);
      }
    };

    // Table config
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Código",
          field: "code",
          width: "10%",
          sortable: false,
          display: function (row) {
            if (row.code) {
              return row.code;
            } else {
              return "-";
            }
          },
        },
        {
          label: "Semilla",
          field: "seed",
          width: "10%",
          sortable: false,
          display: function (row) {
            if (row.seed) {
              return (
                row.seed.variety.charAt(0).toUpperCase() +
                row.seed.variety.slice(1)
              );
            }
          },
        },
        {
          label: "Procedencia",
          field: "origin",
          width: "10%",
          sortable: true,
          display: function (row) {
            if (row.origin) {
              return row.origin;
            } else {
              return "N/A";
            }
          },
        },
        {
          label: "Estado",
          field: "state",
          width: "10%",
          sortable: true,
          display: function (row) {
            if (row.state) {
              switch (row.state) {
                case "SOWN":
                  return "Sembrado";

                case "DISCARDED":
                  return "Descartado";

                case "DELETED":
                  return "Eliminado";

                default:
                  return row.state;
              }
            } else {
              return "N/A";
            }
          },
        },
        {
          label: "Fecha de Estado",
          field: "stateDate",
          width: "10%",
          sortable: true,
          display: function (row) {
            if (row.stateDate) {
              return new Date(row.stateDate).toLocaleDateString("es-CO", {
                timeZone: "UTC",
              });
            } else {
              return "N/A";
            }
          },
        },
      ],
      rows: computed(() => {
        if (!data.rows) {
          return [];
        }
        return data.rows.filter(
          (jar) =>
            jar.seed !== null &&
            jar.seed.variety
              .toLowerCase()
              .includes(searchTerm.value.toLowerCase())
        );
      }),
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      sortable: {
        order: "stateDate",
        sort: "desc",
      },
      messages: {
        pagingInfo: "Mostrando del {0} al {1} de {2} registros",
        pageSizeChangeLabel: "Mostrar:",
        gotoPageLabel: "Página:",
        noDataAvailable: "Sin datos",
      },
    });
    // Get data on first rendering
    myRequest().then((newData) => {
      data.rows = newData;
    });

    const rowClicked = (row) => {
      router.push({
        name: "JarHistoryView",
        params: { id: row._id },
      });
    };
    return {
      searchTerm,
      table,
      rowClicked,
    };
  },
};
</script>

<style scoped>
.section-jars {
  margin-bottom: 3.2rem;
  min-height: 60vh;
}
</style>
