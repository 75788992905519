<template>
  <div class="input-field">
    <div class="input-field-label-container">
      <label class="input-field-label" v-if="label" :for="uuid">{{
        label
      }}</label>
      <TooltipComponent v-if="tooltip" :tooltipText="tooltip" />
    </div>

    <select
      :value="modelValueID"
      class="input-field-box"
      v-bind="{
        ...$attrs,
        onChange: ($event) => {
          $emit('update:modelValue', $event.target.value);
        },
      }"
      :id="uuid"
    >
      <option
        v-for="option in options"
        :value="option[optionValue]"
        :key="option[optionValue]"
        :selected="option[optionValue] === modelValueID"
        :title="option.description"
      >
        {{ option[optionName] }}
      </option>
    </select>
  </div>
</template>

<script>
import UniqueID from "../features/UniqueID";
import TooltipComponent from "@/components/TooltipComponent.vue";

export default {
  components: {
    TooltipComponent,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Object],
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    optionValue: {
      type: String,
      default: "_id",
    },
    optionName: {
      type: String,
      default: "name",
    },
    tooltip: {
      type: String,
      default: "",
    },
  },
  setup() {
    const uuid = UniqueID();
    return {
      uuid,
    };
  },
  computed: {
    modelValueID() {
      if (typeof this.modelValue === "object" && this.modelValue !== null) {
        return this.modelValue._id;
      } else {
        return this.modelValue;
      }
    },
  },
};
</script>

<style>
select {
  cursor: pointer;
}
</style>
