<template>
  <HeaderComponent showMenuOptions jars />

  <section class="section-add-jar">
    <h1 class="heading-primary">Cargar Frasco</h1>
    <form
      autocomplete="off"
      @submit.prevent="addNewJar"
      class="form-input-fields"
    >
      <BaseInput
        label="Código"
        type="text"
        v-model="newJar.code"
        tooltip="Código único e identificador de cada frasco"
      />

      <div class="input-field">
        <div class="input-field-label-container">
          <label class="input-field-label">Semilla</label>
          <TooltipComponent
            tooltipText="Seleccione la variedad de semilla que va a contener el frasco"
          />
        </div>
        <VueMultiselect
          v-model="newJar.seed"
          :options="seeds"
          :close-on-select="true"
          placeholder="Seleccionar"
          label="variety"
          track-by="variety"
        />
      </div>
      <BaseInput
        label="Cantidad (en gramos)"
        type="number"
        v-model="newJar.quantity"
      />
      <BaseInput
        label="Fecha de cosecha"
        type="date"
        v-model="newJar.harvestDate"
      />
      <BaseInput
        label="Fecha de ingreso"
        type="date"
        v-model="newJar.entryDate"
        tooltip="Fecha ingreso a Viracocha Casa de Semillas"
      />
      <BaseInput
        label="Porcentaje de germinación"
        type="number"
        min="0"
        max="100"
        v-model="newJar.germinationPercentages"
      />
      <BaseInput
        label="Procedencia"
        type="text"
        v-model="newJar.origin"
        tooltip="Lugar específico donde se cosechó la semilla (ej. Viracocha)"
      />

      <BaseInput
        label="Observaciones"
        type="text"
        v-model="newJar.remarks"
        tooltip="Otra información relevante"
      />

      <ImageComponent
        label="Foto de frasco"
        @onSelectImage="(image) => (this.photo = image)"
      />

      <div class="page-nav-buttons">
        <router-link class="btn btn-secondary" :to="{ name: 'JarsView' }">
          Volver
        </router-link>

        <button class="btn btn-main btn--right" type="submit">
          Cargar Frasco
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import BaseInput from "@/components/BaseInput.vue";
import router from "@/router/router";
import Utils from "@/features/Utils";
import VueMultiselect from "vue-multiselect";
import TooltipComponent from "@/components/TooltipComponent.vue";
import ImageComponent from "@/components/image/ImageComponent.vue";

export default {
  data() {
    return {
      photo: null,
    };
  },
  components: {
    HeaderComponent,
    BaseInput,
    VueMultiselect,
    TooltipComponent,
    ImageComponent,
  },
  created() {
    (async () => {
      this.$isLoading(true);
      try {
        await this.$store.dispatch("seed/fetchSeeds");
      } catch (err) {
        this.$swal({
          title: "¡Error!",
          text: err.response.data
            ? err.response.data.message
            : "Error al obtener respuesta de servicio",
          icon: "error",
          confirmButtonColor: "#2eb086",
        });
      }
      this.$isLoading(false);
    })();
  },
  computed: {
    newJar() {
      return this.$store.state.jar.newJar;
    },
    seeds() {
      const seeds = this.$store.state.seed.seeds;
      seeds.sort((a, b) => a.variety.localeCompare(b.variety));
      return seeds;
    },
  },
  methods: {
    async addNewJar() {
      this.$isLoading(true);
      try {
        const newJarData = JSON.parse(JSON.stringify(this.newJar));
        const createdJar = await this.$store.dispatch(
          "jar/createJar",
          newJarData
        );
        // Clean add seed steps state
        this.$store.dispatch("jar/resetNewJarState");

        // Upload images if exists
        if (this.photo) {
          const formData = new FormData();
          formData.append("photo", this.photo);

          await this.$store.dispatch("jar/uploadImage", {
            id: createdJar.id,
            formData,
          });
        }

        // Navigate to jars
        router.push("/jars");
        Utils.toast.fire({
          icon: "success",
          title: "¡Frasco cargado exitosamente!",
        });
      } catch (err) {
        this.$swal({
          title: "¡Error!",
          text: err.response.data
            ? err.response.data.message
            : "Error al obtener respuesta de servicio",
          icon: "error",
          confirmButtonColor: "#2eb086",
        });
      }
      this.$isLoading(false);
    },
  },
};
</script>

<style scoped>
.steps-nav {
  display: flex;
  align-content: center;
}
</style>
