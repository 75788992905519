<template>
  <HeaderComponent showMenuOptions />

  <section class="section-jars animate__animated animate__fadeIn">
    <h1 class="heading-primary">Caducidad</h1>

    <TableExpirations />
  </section>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import TableExpirations from "@/components/TableExpirations.vue";

export default {
  name: "ExpirationsView",
  components: {
    HeaderComponent,
    TableExpirations,
  },
};
</script>

<style scoped>
.section-jars {
  margin-bottom: 3.2rem;
  min-height: 60vh;
}
</style>
