<template>
  <header class="header">
    <nav class="main-nav">
      <router-link class="viracocha-logo" :to="preLogin ? '/' : '/start'">
        <span class="viracocha-logo-text">Viracocha</span>
        <img src="../assets/viracocha-logo.svg" alt="Plant logo" />
      </router-link>

      <div v-if="showMenuOptions" class="main-nav-list">
        <router-link
          class="nav-option"
          :class="seeds ? 'active' : null"
          :to="{ name: 'SeedsView' }"
        >
          <span>Semillas</span>
        </router-link>
        <router-link
          class="nav-option"
          :class="jars ? 'active' : null"
          :to="{ name: 'JarsView' }"
        >
          <span>Frascos</span>
        </router-link>

        <router-link class="nav-option" :to="{ name: 'ExpirationsView' }">
          <span>Caducidad</span>
        </router-link>

        <router-link
          class="nav-option"
          :class="history ? 'active' : null"
          :to="{ name: 'HistoryView' }"
        >
          <span>Historial</span>
        </router-link>

        <!-- Removed User module from navigation, could be restored in the future -->
        <!-- <router-link class="nav-option" :to="{ name: 'UserView' }">
          <span>Usuario</span>
        </router-link> -->
      </div>
      <button v-if="loggedIn" class="nav-option logout-option" @click="logout">
        <span>Cerrar</span>
      </button>
    </nav>
    <div class="mobile-nav">
      <router-link class="viracocha-logo-mobile" :to="{ name: 'StartView' }">
        <img src="../assets/viracocha-logo-mobile.svg" alt="Plant logo" />
      </router-link>
      <button
        v-if="showMenuOptions"
        @click="toggleClass"
        class="btn-mobile-nav"
      >
        <img
          class="burger-nav-icon"
          src="../assets/burger-nav-icon.svg"
          alt="burger menu"
        />
        <img
          class="close-nav-icon"
          src="../assets/close-nav-icon.svg"
          alt="close menu"
        />
      </button>
    </div>
  </header>
</template>

<script>
import { authComputed } from "../store/helpers.js";

export default {
  name: "HeaderComponent",
  props: {
    showMenuOptions: {
      type: Boolean,
      required: false,
    },
    preLogin: {
      type: Boolean,
      required: false,
    },
    seeds: {
      type: Boolean,
      default: false,
    },
    jars: {
      type: Boolean,
      default: false,
    },
    history: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleClass() {
      const headerEl = document.querySelector(".header");
      headerEl.classList.toggle("nav-open");
    },
    logout() {
      this.$store.dispatch("user/logout");
    },
  },
  computed: {
    ...authComputed,
  },
};
</script>

<style scoped>
.header {
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  position: relative;
}

.main-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 8888;
}

.main-nav-list {
  list-style: none;
  display: flex;
  align-items: center;
}

.nav-option {
  font-weight: 700;
  font-size: 2rem;
  padding: 1.7rem;
  height: fit-content;
  margin: 0 3rem;
  color: #777;
  border-radius: 3rem;

  display: flex;
  align-items: center;

  transition: all 0.3s;
}

.nav-option:hover {
  background: #eee;
}

.nav-option.router-link-active {
  background: #e6fcf5;
  border: 1px solid #96f2d7;
}

.nav-option.router-link-exact-active {
  color: #2eb086;
}

.active {
  background: #e6fcf5;
  color: #2eb086;
  border: 1px solid #96f2d7;
}

.nav-bar-menu {
  display: flex;
}

.logout-option {
  background: none;
  cursor: pointer;
  border: none;
  font-weight: 600;
}

/* ________ MOBILE _______ */

.mobile-nav {
  display: flex;
  justify-content: space-between;
}

.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;
  padding: 3rem;

  display: none;
}

.icon-mobile-nav {
  height: 5.2rem;
  width: 5.2rem;
  color: #333;
}

.close-nav-icon {
  display: none;
}

/* Queries */

@media (max-width: 75em) {
  .nav-option {
    margin: 0 2rem 0 0;
  }
}

@media (max-width: 59em) {
  /* MOBILE NAVIGATION */
  .btn-mobile-nav {
    display: block;
    z-index: 9999;
  }

  .main-nav {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in;

    /* Hide Navigation */
    /* Allow NO transitions at ALL*/
    /* display: none; */

    /* 1) Hide it visually */
    opacity: 0;

    /* 2) Make it unaccessible to mouse and keyboard */
    pointer-events: none;

    /* 3) Hide it from screen readers */
    visibility: hidden;
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .nav-open .close-nav-icon {
    display: block;
  }

  .nav-open .burger-nav-icon {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
    gap: 4.8rem;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 3rem;
  }

  .logout-option {
    display: none;
  }

  .nav-option {
    font-size: 2.2rem;
  }
}
</style>
