<template>
  <router-view />
</template>

<script>
// import axios from "axios";
import { apiClient } from "./services/DBService";
import router from "@/router/router";

export default {
  created() {
    const userString = localStorage.getItem("userData"); // grab user data from local storage
    if (userString) {
      // check to see if there is indeed a user
      const userData = JSON.parse(userString); // parse user data into JSON
      this.$store.dispatch("user/setUserData", userData); // restore user data with Vuex
    }

    apiClient.interceptors.response.use(
      (response) => response, // simply return the response
      (error) => {
        if (error.response.status === 401) {
          // if we catch a 401 error
          this.$store.dispatch("user/logoutInterceptor"); // force a log out
          router.push("/");
        }
        return Promise.reject(error); // reject the Promise, with the error as the reason
      }
    );
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  /* 10px / 16px = 0.625 = 62,5% */
  font-size: 62.5%;
  overflow-x: hidden;
  /* Does NOT work on Safari  */
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
  line-height: 1;
  font-weight: 500;
  color: #555;

  /* Only works if there is nothing absolutely
  positioned in relation to body */
  overflow-x: hidden;
}

/* This reset could be userful, but it's necessary to research side effects */
/* button {
  all: unset;
} */

::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: #2eb086;
}

::selection {
  color: #fff;
  background: #2eb086;
}

@font-face {
  font-family: "pass";
  font-style: normal;
  font-weight: 400;
  src: url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAATsAA8AAAAAB2QAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABWAAAABwAAAAcg9+z70dERUYAAAF0AAAAHAAAAB4AJwANT1MvMgAAAZAAAAA/AAAAYH7AkBhjbWFwAAAB0AAAAFkAAAFqZowMx2N2dCAAAAIsAAAABAAAAAQAIgKIZ2FzcAAAAjAAAAAIAAAACAAAABBnbHlmAAACOAAAALkAAAE0MwNYJ2hlYWQAAAL0AAAAMAAAADYPA2KgaGhlYQAAAyQAAAAeAAAAJAU+ATJobXR4AAADRAAAABwAAAAcCPoA6mxvY2EAAANgAAAAEAAAABAA5gFMbWF4cAAAA3AAAAAaAAAAIAAKAE9uYW1lAAADjAAAARYAAAIgB4hZ03Bvc3QAAASkAAAAPgAAAE5Ojr8ld2ViZgAABOQAAAAGAAAABuK7WtIAAAABAAAAANXulPUAAAAA1viLwQAAAADW+JM4eNpjYGRgYOABYjEgZmJgBEI2IGYB8xgAA+AANXjaY2BifMg4gYGVgYVBAwOeYEAFjMgcp8yiFAYHBl7VP8wx/94wpDDHMIoo2DP8B8kx2TLHACkFBkYA8/IL3QB42mNgYGBmgGAZBkYGEEgB8hjBfBYGDyDNx8DBwMTABmTxMigoKKmeV/3z/z9YJTKf8f/X/4/vP7pldosLag4SYATqhgkyMgEJJnQFECcMOGChndEAfOwRuAAAAAAiAogAAQAB//8AD3jaY2BiUGJgYDRiWsXAzMDOoLeRkUHfZhM7C8Nbo41srHdsNjEzAZkMG5lBwqwg4U3sbIx/bDYxgsSNBRUF1Y0FlZUYBd6dOcO06m+YElMa0DiGJIZUxjuM9xjkGRhU2djZlJXU1UDQ1MTcDASNjcTFQFBUBGjYEkkVMJCU4gcCKRTeHCk+fn4+KSllsJiUJEhMUgrMUQbZk8bgz/iA8SRR9qzAY087FjEYD2QPDDAzMFgyAwC39TCRAAAAeNpjYGRgYADid/fqneL5bb4yyLMwgMC1H90HIfRkCxDN+IBpFZDiYGAC8QBbSwuceNpjYGRgYI7594aBgcmOAQgYHzAwMqACdgBbWQN0AAABdgAiAAAAAAAAAAABFAAAAj4AYgI+AGYB9AAAAAAAKgAqACoAKgBeAJIAmnjaY2BkYGBgZ1BgYGIAAUYGBNADEQAFQQBaAAB42o2PwUrDQBCGvzVV9GAQDx485exBY1CU3PQgVgIFI9prlVqDwcZNC/oSPoKP4HNUfQLfxYN/NytCe5GwO9/88+/MBAh5I8C0VoAtnYYNa8oaXpAn9RxIP/XcIqLreZENnjwvyfPieVVdXj2H7DHxPJH/2/M7sVn3/MGyOfb8SWjOGv4K2DRdctpkmtqhos+D6ISh4kiUUXDj1Fr3Bc/Oc0vPqec6A8aUyu1cdTaPZvyXyqz6Fm5axC7bxHOv/r/dnbSRXCk7+mpVrOqVtFqdp3NKxaHUgeod9cm40rtrzfrt2OyQa8fppCO9tk7d1x0rpiQcuDuRkjjtkHt16ctbuf/radZY52/PnEcphXpZOcofiEZNcQAAeNpjYGIAg///GBgZsAF2BgZGJkZmBmaGdkYWRla29JzKggxD9tK8TAMDAxc2D0MLU2NjENfI1M0ZACUXCrsAAAABWtLiugAA)
    format("woff");
}

input[type="password"]:not(:placeholder-shown) {
  font-family: "pass", "Roboto", Helvetica, Arial, sans-serif;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #2eb086;
}

h4 {
  font-size: 20px;
}

a {
  text-decoration: none;
  color: #333;
}

a:active {
  color: #333;
}

pre {
  font-size: 1.6rem;
}

.viracocha-logo-text {
  font-size: 3rem;
  color: #2eb086;
}

.viracocha-logo {
  display: flex;
  gap: 0.7rem;
  width: min-content;
  padding: 3rem;
  /* align-content: center; */
}

.viracocha-logo-mobile {
  /* padding: 3rem; */
  display: none;
}

.viracocha-logo-mobile img {
  width: 5.3rem;
}

.btn {
  max-width: fit-content;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  align-self: flex-end;
  padding: 1.3rem 1.5rem;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  font-family: inherit;
}

.btn-small {
  padding: 0.6rem;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-content: center;
}

.btn-main {
  background: #2eb086;
}

.btn-main:hover {
  background: #299d78;
}

.btn-secondary {
  background: #495057;
}

.btn-secondary:hover {
  background: #666b70;
}

.btn-delete {
  background: #ff8787;
}

.btn-delete:hover {
  background: #f96f6f;
}

.btn-discard {
  background: #868e96;
}

.btn-discard:hover {
  background: #949ba1;
}

.btn-sow {
  background: #228be6;
}

.btn-sow:hover {
  background: #339af0;
}

.btn-signup {
  /* padding: 1.3rem 1.5rem; */
  color: #333;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-right: 12px;
  box-shadow: none;
  padding: 0;
}

.btn--right {
  justify-self: end;
}

.btn--add {
  padding: 10px 12px;
}

.btn--add-small {
  padding: 2.31px 7px;
  border-radius: 50px;
  transform: translateY(14px);
  font-size: 1.6rem;
}

.btn--add-small:hover {
  transform: translateY(12px) !important;
}

.btn--add-small:active {
  transform: translateY(14px) !important;
}

.btn--refresh {
  padding: 8px 12px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.btn:hover {
  transform: translateY(-2px);
}

.btn:active {
  transform: translateY(0);
  color: #fff;
}

.heading-primary {
  font-size: 4rem;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-top: 10rem;
}

.form-input-fields {
  max-width: 80rem;
  margin: 0 auto;
  margin-top: 8rem;
  display: grid;
  gap: 5rem;
  column-gap: 10rem;
  grid-template-columns: 1fr 1fr;
}

.input-field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-field-label-container {
  display: flex;
  gap: 9px;
  align-items: center;
}

.add-input-field {
  display: flex;
  flex-direction: column;
}

.input-field-label {
  font-size: 1.4rem;
  font-weight: 500;
  color: #333;
}

.input-field-box {
  background: #ffffff;
  border: 1px solid #cdcdcd;
  /* border: 1px solid #9e9e9e; */
  /* gg shadow */

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 9px;
  height: 4rem;
  min-width: 10rem;
  padding-left: 10px;
  font-size: 1.4rem;
  font-family: inherit;
  font-style: inherit;
}

.input-field-box:focus {
  border: 1px solid #2eb086;
  outline: #2eb086 1px solid;
}

.input-field-box::placeholder {
  opacity: 0.7;
}

.input-field-box:disabled {
  background: #eee;
  opacity: 0.75;
}

.input-error {
  border: 1px solid red;
}

.bold {
  font-weight: 600;
}

.steps-nav {
  display: flex;
  justify-content: center;
  margin-top: 10rem;
}

.page-nav-buttons {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / 3;
  margin-bottom: 2.8rem;
}

/* Sweet alert */

.swal2-popup {
  font-size: 1.4rem !important;
  border-radius: 12px !important;
}

.swal2-toast {
  font-size: 1.6rem !important;
}

.swal2-confirm,
.swal2-cancel,
.swal2-deny {
  font-weight: 700 !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%) !important;
  border-radius: 6px !important;
}

.swal2-input {
  /* margin-top: 1.2rem; */
  background: #ffffff;
  border: 1px solid #9e9e9e !important;
  /* gg shadow */

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04) !important;
  border-radius: 9px !important;
  height: 3.5rem !important;
  min-width: 10rem !important;
  padding-left: 10px !important;
}

.swal2-input:focus {
  border: 1px solid #2eb086 !important;
  outline: #2eb086 1px solid !important;
}

.swal2-inputerror:focus {
  border: 1px solid #f27474 !important;
  outline: #f27474 1px solid !important;
}

/* Table */

.table-container {
  max-width: 180rem;
  padding: 0 10rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 10rem;
}

.table-search-box {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 1.4rem;
}

.table-menu,
.table-menu-mobile {
  display: flex;
  justify-content: space-between;
}

.table-menu-mobile {
  display: none;
}

.vtl-table {
  border-radius: 9px;
}

.vtl-tbody-tr:hover {
  background-color: #e9ecef !important;
  transition: all 0.3s;
}

.vtl-table .vtl-thead .vtl-thead-th {
  color: #333;
  background-color: #e6fcf5;
  border-color: #e6fcf5;
  font-size: 1.4rem;
  padding: 2rem;
}

.vtl-tbody-td,
.vtl-tbody-tr {
  font-size: 1.4rem;
  padding: 2rem !important;
  border: none !important;
}

.vtl-paging-count-label,
.vtl-paging-page-label,
.vtl-empty-msg,
.vtl-paging-info {
  color: #333;
  font-size: 1.4rem;
}

.vtl-empty-msg {
  padding-top: 1.4rem;
}

.vtl-paging-pagination-page-link.vtl-paging-pagination-page-link-first.page-link,
.vtl-paging-pagination-page-link.vtl-paging-pagination-page-link-prev.page-link,
.vtl-paging-pagination-page-link.vtl-paging-pagination-page-link-next.page-link,
.vtl-paging-pagination-page-link.vtl-paging-pagination-page-link-last.page-link {
  color: #2eb086;
}

.vtl-paging-pagination-page-link.vtl-paging-pagination-page-link-number.page-link {
  color: #2eb086;
}

.vtl-paging-pagination-page-li-number.page-item.disabled .page-link {
  background-color: #eee;
  color: #333 !important;
  font-weight: 600 !important;
}

.vtl-paging-pagination-page-link {
  font-size: 1.4rem;
  color: #2eb086;
}

.vtl-paging-change-div {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.vtl-paging {
  margin-top: 1rem;
  align-items: center;
  padding: 1rem;
  border-top: 2px solid #eee;
}

.vtl-paging-count-dropdown,
.vtl-paging-page-dropdown {
  font-size: 14px;
  background: #eee !important;
  color: #555;
  font-weight: 600;
  padding: 5px !important;
  border-radius: 5px;
}

.vtl-paging-pagination-ul {
  gap: 5px;
  font-weight: 600;
}

.vtl-paging-pagination-page-link {
  padding: 1rem !important;
  border-radius: 11px;
}

.vtl-paging-pagination-page-link-number {
  padding: 1rem 1.5rem !important;
}

.vtl-loading-content span {
  display: none;
  font-size: 1.4rem;
}

.vtl-loading-content::after {
  content: url(./assets/loading-spinner.svg);
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
}

.vtl-loading-mask {
  background-color: #868e96a1 !important;
  border-radius: 9px;
}

/* Multiselect */
fieldset[disabled] .multiselect {
  pointer-events: none;
}
.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: #41b883;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}
.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 1.4rem;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}
.multiselect * {
  box-sizing: border-box;
}
.multiselect:focus {
  outline: none;
}
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
  opacity: 0.6;
}
.multiselect--active {
  z-index: 50;
}
.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active .multiselect__select {
  transform: rotate(180deg);
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 0 0 0 5px;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}
.multiselect__input:-ms-input-placeholder {
  color: #35495e;
}
.multiselect__input::placeholder {
  color: #35495e;
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}
.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px;
}
.multiselect__tags-wrap {
  display: inline;
}
.multiselect__tags {
  min-height: 4rem;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 9px;
  /* border: 1px solid #e8e8e8; */
  border: 1px solid #cdcdcd;
  background: #fff;
  font-size: 1.4rem;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%);
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #2eb086;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.multiselect__tag-icon:after {
  content: "\D7";
  color: #266d4d;
  font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #2eb086;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff;
}
.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 30px 0 12px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}
.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
}
.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: "";
}
.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
.multiselect--active .multiselect__placeholder {
  display: none;
}
.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}
.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}
.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}
.multiselect__content::webkit-scrollbar {
  display: none;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}
.multiselect__option--highlight {
  background: #2eb086;
  outline: none;
  color: #fff;
}
.multiselect__option--highlight:after {
  content: "Agregar";
  background: #2eb086;
  color: #fff;
}
.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700;
}
.multiselect__option--selected:after {
  content: "Seleccionado";
  color: silver;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #ff8787;
  color: #fff;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff8787;
  content: "Eliminar";
  color: #fff;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}
.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}
.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}
.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}
.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}
.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff8787;
  color: #fff;
}
.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff8787;
  content: "Eliminar";
  color: #fff;
}
.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}
[dir="rtl"] .multiselect {
  text-align: right;
}
[dir="rtl"] .multiselect__select {
  right: auto;
  left: 1px;
}
[dir="rtl"] .multiselect__tags {
  padding: 8px 8px 0 40px;
}
[dir="rtl"] .multiselect__content {
  text-align: right;
}
[dir="rtl"] .multiselect__option:after {
  right: auto;
  left: 0;
}
[dir="rtl"] .multiselect__clear {
  right: auto;
  left: 12px;
}
[dir="rtl"] .multiselect__spinner {
  right: auto;
  left: 1px;
}
@keyframes spinning {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}

/* Queries */
@media (max-width: 75em) {
  .vtl-paging-info {
    display: none;
  }

  .vtl-pagination,
  .vtl-paging {
    justify-content: space-between;
  }
}

@media (max-width: 59em) {
  .viracocha-logo {
    display: none;
  }

  .viracocha-logo-mobile {
    padding: 3rem;
    display: block;
  }

  .heading-primary {
    font-size: 3.2rem;
    margin-top: 0rem;
  }

  .form-input-fields {
    max-width: 50rem;
    padding: 0 4rem;
    margin-top: 6rem;
    grid-template-columns: 1fr;
    gap: 2.6rem;
  }

  .page-nav-buttons {
    grid-column: 1 / 2;
  }

  .steps-nav {
    margin-top: 1.8rem;
  }

  /* Table mobile */
  .table-container {
    padding: 0 2rem;
    margin-top: 5rem;
  }

  .table-menu {
    display: none;
  }

  .table-menu-mobile {
    display: flex;
  }

  .vtl-paging-change-div,
  .vtl-paging-info {
    display: none;
  }

  .vtl-pagination,
  .vtl-paging {
    justify-content: center !important;
  }
}
</style>
