import { apiClient } from "./DBService";

export default {
  postJar(jar) {
    return apiClient.post("/jars", jar);
  },
  getJars() {
    return apiClient.get("/jars");
  },
  getJarsHistory() {
    return apiClient.get("/jars/history");
  },
  getJarsCloseToExpire() {
    return apiClient.get("/jars/closeToExpire");
  },
  getJar(id) {
    return apiClient.get("/jars/" + id);
  },
  updateJar(id, jar) {
    return apiClient.patch("/jars/" + id, jar);
  },
  deleteJar(id) {
    return apiClient.patch("/jars/delete/" + id);
  },
  discardJar(id) {
    return apiClient.patch("/jars/discard/" + id);
  },
  sowJar(id) {
    return apiClient.patch("/jars/sow/" + id);
  },
  uploadImage(id, formData) {
    return apiClient.patch("/jars/images/" + id, formData);
  },
  deleteImage(id) {
    return apiClient.delete("/jars/images/" + id);
  },
};
