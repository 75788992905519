<template>
  <HeaderComponent showMenuOptions />
  <section class="section-seeds animate__animated animate__fadeIn">
    <h1 class="heading-primary">Semillas</h1>

    <div class="table-container">
      <div class="table-menu">
        <div class="table-search-box">
          <label>Buscar:</label>
          <input class="input-field-box" v-model="searchTerm" />
        </div>

        <router-link
          class="btn btn-main btn--add"
          :to="{ name: 'AddSeedStep1' }"
        >
          + Agregar Semilla
        </router-link>
      </div>

      <div class="table-menu-mobile">
        <div class="table-search-box">
          <input
            class="input-field-box"
            placeholder="Buscar..."
            v-model="searchTerm"
          />
        </div>

        <router-link
          class="btn btn-main btn--add"
          :to="{ name: 'AddSeedStep1' }"
        >
          + Agregar
        </router-link>
      </div>

      <VueTableLite
        :is-static-mode="true"
        :is-loading="table.isLoading"
        :columns="table.columns"
        :rows="table.rows"
        :total="table.totalRecordCount"
        :sortable="table.sortable"
        :messages="table.messages"
        @row-clicked="rowClicked"
      ></VueTableLite>
    </div>
  </section>
</template>

<script>
"use strict";

import HeaderComponent from "@/components/HeaderComponent.vue";
import { reactive, ref, computed } from "vue";
import VueTableLite from "vue3-table-lite";
import { useStore } from "vuex";
import router from "@/router/router";

export default {
  name: "SeedsView",
  components: {
    HeaderComponent,
    VueTableLite,
  },
  setup() {
    const searchTerm = ref(""); // Search text
    const data = reactive({
      rows: [],
    });
    const store = useStore();

    /**
     * Get server data request
     */
    const myRequest = async () => {
      table.isLoading = true;
      try {
        const seeds = await store.dispatch("seed/fetchSeeds");
        table.isLoading = false;
        return seeds;
      } catch (err) {
        console.error(err);
      }
    };

    // Table config
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Variedad",
          field: "variety",
          width: "10%",
          sortable: true,
          display: function (row) {
            return row.variety.charAt(0).toUpperCase() + row.variety.slice(1);
          },
        },
        {
          label: "Nombre Científico",
          field: "scientificName",
          width: "10%",
          sortable: true,
          display: function (row) {
            if (row.scientificName) {
              return (
                row.scientificName.charAt(0).toUpperCase() +
                row.scientificName.slice(1)
              );
            } else {
              return "N/A";
            }
          },
        },
        {
          label: "Categorías",
          field: "categories",
          width: "10%",
          sortable: true,
          display: function (row) {
            if (row.categories) {
              return row.categories.name;
            } else {
              return "N/A";
            }
          },
        },
        {
          label: "Familia",
          field: "family",
          width: "10%",
          sortable: true,
          display: function (row) {
            if (row.family) {
              return row.family.name;
            } else {
              return "N/A";
            }
          },
        },
        {
          label: "Rango Altitudinal",
          field: "range",
          width: "10%",
          sortable: false,
          display: function (row) {
            if (row.range && row.range.to) {
              return `Desde ${row.range.from ? row.range.from : 0} hasta ${
                row.range.to
              } mts.`;
            } else {
              return "N/A";
            }
          },
        },
        {
          label: "Periodo de almacenamiento (en meses)",
          field: "storagePeriod",
          width: "10%",
          sortable: true,
          display: function (row) {
            if (row.storagePeriod) {
              return row.storagePeriod;
            } else {
              return "N/A";
            }
          },
        },
      ],
      rows: computed(() => {
        if (!data.rows) {
          return [];
        }
        // Filter by variety, scientific name, categories and family
        return data.rows.filter(
          (seed) =>
            seed.variety
              .toLowerCase()
              .includes(searchTerm.value.toLowerCase()) ||
            seed.scientificName
              .toLowerCase()
              .includes(searchTerm.value.toLowerCase()) ||
            (seed.categories &&
              seed.categories.name
                .toLowerCase()
                .includes(searchTerm.value.toLowerCase())) ||
            (seed.family &&
              seed.family.name
                .toLowerCase()
                .includes(searchTerm.value.toLowerCase()))
        );
      }),
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      sortable: {
        order: "id",
        sort: "asc",
      },
      messages: {
        pagingInfo: "Mostrando del {0} al {1} de {2} registros",
        pageSizeChangeLabel: "Mostrar:",
        gotoPageLabel: "Página:",
        noDataAvailable: "Sin datos",
      },
    });
    // Get data on first rendering
    myRequest().then((newData) => {
      data.rows = newData;
    });

    const rowClicked = (row) => {
      router.push({
        name: "EditSeed",
        params: { id: row._id },
      });
    };
    return {
      searchTerm,
      table,
      rowClicked,
    };
  },
};
</script>

<style scoped>
.section-seeds {
  margin-bottom: 3.2rem;
  min-height: 60vh;
}
</style>
