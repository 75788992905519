<template>
  <router-link class="menu-option" :to="{ name: menuOption.optionView }">
    <img
      class="menu-option-img"
      :src="menuOption.optionImage"
      :alt="menuOption.optionName"
    />
    <span class="menu-option-text">{{ menuOption.optionName }}</span>
  </router-link>
</template>

<script>
export default {
  name: "StartMenuOption",
  props: {
    menuOption: Object,
  },
};
</script>

<style>
.menu-option {
  display: flex;
  gap: 1rem;
  width: 20rem;
  height: 20rem;
  background: #fff;
  border: 2px solid #eee;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
  border-radius: 100rem;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  color: #777;
}

.menu-option:hover {
  transform: translateY(-0.7rem);
  box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.06);
  border: 2px solid #2eb086;
  background-color: #e6fcf5;
  background: #fff;
  color: #555;
}

.menu-option:active {
  transform: translateY(0);
}

.menu-option-img {
  height: 5rem;
}

.menu-option-text {
  font-weight: 700;
  font-size: 2rem;
}

@media (max-width: 59em) {
  .menu-option {
    height: 8.4rem;
    width: 24rem;
  }
}
</style>
