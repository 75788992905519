<template>
  <HeaderComponent showMenuOptions />

  <section class="section-user">
    <h1 class="heading-primary">Usuario</h1>

    <form class="form-input-fields">
      <BaseInput
        v-model="user.fullName"
        label="Nombre completo"
        type="text"
        disabled
      />

      <BaseInput
        v-model="user.email"
        label="Email"
        type="email"
        disabled
        autocomplete
      />

      <BaseInput v-model="user.phone" label="Celular" type="tel" disabled />

      <BaseSelect :options="roles" v-model="user.role" label="Rol" disabled />

      <BaseInput
        v-model="user.password"
        label="Contraseña"
        type="password"
        autocomplete
        disabled
      />

      <BaseInput
        v-model="user.repeatPassword"
        label="Repetir Contraseña"
        type="password"
        autocomplete
        disabled
      />

      <div class="page-nav-buttons">
        <router-link class="btn btn-secondary" :to="{ name: 'StartView' }">
          Volver
        </router-link>
        <router-link
          class="btn btn-main btn--right"
          :to="{ name: 'StartView' }"
        >
          Modificar
        </router-link>
      </div>
    </form>
  </section>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseSelect from "@/components/BaseSelect.vue";

export default {
  name: "UserView",
  components: {
    HeaderComponent,
    BaseInput,
    BaseSelect,
  },
  data() {
    return {
      roles: [
        {
          id: 1,
          name: "Administrador",
          value: "ADMIN",
        },
        {
          id: 2,
          name: "Director",
          value: "DIRECTOR",
        },
        {
          id: 3,
          name: "Auxiliar",
          value: "ASSISTANT",
        },
        {
          id: 4,
          name: "Aprendiz",
          value: "APPRENTICE",
        },
        {
          id: 5,
          name: "Administrador de finca",
          value: "ESTATE-MANAGER",
        },
      ],
      user: {
        fullName: "",
        email: "",
        phone: "",
        role: "",
        password: "",
        repeatPassword: "",
      },
    };
  },
};
</script>

<style scoped>
.section-user {
  height: auto;
}
</style>
