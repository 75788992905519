import { apiClient } from "./DBService";

export default {
  postSeed(seed) {
    return apiClient.post("/seeds", seed);
  },
  getSeeds() {
    return apiClient.get("/seeds");
  },
  getSeed(id) {
    return apiClient.get("/seeds/" + id);
  },
  updateSeed(id, seed) {
    return apiClient.patch("/seeds/" + id, seed);
  },
  deleteSeed(id) {
    return apiClient.delete("/seeds/" + id);
  },
  uploadImage(id, formData) {
    return apiClient.patch("/seeds/images/" + id, formData);
  },
  deleteImage(id, resource) {
    return apiClient.delete("/seeds/images/" + id, {
      params: { resource },
    });
  },
};
