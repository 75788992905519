import SeedDetailService from "@/services/SeedDetailService";

export const namespaced = true;

export const state = {
  categories: [],
  families: [],
  pollination: [],
  growthForm: [],
  germinationMethod: [],
  dispersionMethod: [],
  cropDuration: [],
  bioIntensiveMethod: [],
  properties: [],
  typicalUses: [],
};

export const mutations = {
  SET_CATEGORIES(state, categoriesDB) {
    state.categories = categoriesDB;
  },
  SET_FAMILIES(state, familiesDB) {
    state.families = familiesDB;
  },
  SET_POLLINATION(state, pollinationDB) {
    state.pollination = pollinationDB;
  },
  SET_GROWTH_FORM(state, growthFormDB) {
    state.growthForm = growthFormDB;
  },
  SET_GERMINATION_METHOD(state, germinationMethodDB) {
    state.germinationMethod = germinationMethodDB;
  },
  SET_DISPERSION_METHOD(state, dispersionMethodDB) {
    state.dispersionMethod = dispersionMethodDB;
  },
  SET_CROP_DURATION(state, cropDurationDB) {
    state.cropDuration = cropDurationDB;
  },
  SET_BIO_INTENSIVE_METHOD(state, bioIntensiveMethodDB) {
    state.bioIntensiveMethod = bioIntensiveMethodDB;
  },
  SET_PROPERTIES(state, propertiesDB) {
    state.properties = propertiesDB;
  },
  SET_TYPICAL_USES(state, typicalUsesDB) {
    state.typicalUses = typicalUsesDB;
  },
  ADD_FAMILY(state, family) {
    state.families.push(family);
  },
  ADD_PROPERTY(state, property) {
    state.properties.push(property);
  },
  ADD_TYPICAL_USE(state, typicalUse) {
    state.typicalUses.push(typicalUse);
  },
};

export const actions = {
  async fetchCategories({ commit }) {
    if (state.categories.length === 0) {
      const categoriesDB = await SeedDetailService.getCategories();
      commit("SET_CATEGORIES", categoriesDB.data.data.categories);
    }
  },
  async fetchFamilies({ commit }) {
    if (state.families.length === 0) {
      const familiesDB = await SeedDetailService.getFamilies();
      commit("SET_FAMILIES", familiesDB.data.data.families);
    }
  },
  async fetchPollination({ commit }) {
    if (state.pollination.length === 0) {
      const pollinationDB = await SeedDetailService.getPollination();
      commit("SET_POLLINATION", pollinationDB.data.data.pollination);
    }
  },
  async fetchGrowthForm({ commit }) {
    if (state.growthForm.length === 0) {
      const growthFormDB = await SeedDetailService.getGrowthForm();
      commit("SET_GROWTH_FORM", growthFormDB.data.data.growthForm);
    }
  },
  async fetchGerminationMethod({ commit }) {
    if (state.germinationMethod.length === 0) {
      const germinationMethodDB =
        await SeedDetailService.getGerminationMethod();
      commit(
        "SET_GERMINATION_METHOD",
        germinationMethodDB.data.data.germinationMethod
      );
    }
  },
  async fetchDispersionMethod({ commit }) {
    if (state.dispersionMethod.length === 0) {
      const dispersionMethodDB = await SeedDetailService.getDispersionMethod();

      commit(
        "SET_DISPERSION_METHOD",
        dispersionMethodDB.data.data.dispersionMethod
      );
    }
  },
  async fetchCropDuration({ commit }) {
    if (state.cropDuration.length === 0) {
      const cropDurationDB = await SeedDetailService.getCropDuration();
      commit("SET_CROP_DURATION", cropDurationDB.data.data.cropDuration);
    }
  },
  async fetchBioIntensiveMethod({ commit }) {
    if (state.bioIntensiveMethod.length === 0) {
      const bioIntensiveMethodDB =
        await SeedDetailService.getBioIntensiveMethod();

      commit(
        "SET_BIO_INTENSIVE_METHOD",
        bioIntensiveMethodDB.data.data.bioIntensiveMethod
      );
    }
  },
  async fetchProperties({ commit }) {
    if (state.properties.length === 0) {
      const propertiesDB = await SeedDetailService.getProperties();
      commit("SET_PROPERTIES", propertiesDB.data.data.properties);
    }
  },
  async fetchTypicalUses({ commit }) {
    if (state.typicalUses.length === 0) {
      const typicalUsesDB = await SeedDetailService.getTypicalUses();
      commit("SET_TYPICAL_USES", typicalUsesDB.data.data.typicalUses);
    }
  },
  async createFamily({ commit }, family) {
    const familyDB = await SeedDetailService.postFamily(family);
    commit("ADD_FAMILY", familyDB.data.data.family);
  },
  async createProperty({ commit }, property) {
    const propertyDB = await SeedDetailService.postProperty(property);
    commit("ADD_PROPERTY", propertyDB.data.data.property);
  },
  async createTypicalUse({ commit }, typicalUse) {
    const typicalUseDB = await SeedDetailService.postTypicalUse(typicalUse);
    commit("ADD_TYPICAL_USE", typicalUseDB.data.data.typicalUse);
  },
};
