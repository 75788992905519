<template>
  <div class="input-field">
    <div class="input-field-label-container">
      <label
        class="input-field-label"
        :class="home ? 'input-field-label--home' : null"
        :for="uuid"
        >{{ label }}</label
      >
      <TooltipComponent v-if="tooltip" :tooltipText="tooltip" />
    </div>

    <input
      v-bind="$attrs"
      :placeholder="label"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :id="uuid"
      :aria-describedby="error ? `${uuid}-error` : null"
      :aria-invalid="error ? true : null"
      class="input-field-box"
      :class="error ? 'input-error' : null"
    />
  </div>
  <p
    v-if="error"
    class="errorMessage"
    :id="`${uuid}-error`"
    aria-live="assertive"
  >
    {{ error }}
  </p>
</template>

<script>
import UniqueID from "../features/UniqueID";
import TooltipComponent from "@/components/TooltipComponent.vue";

export default {
  components: {
    TooltipComponent,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    home: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "",
    },
  },
  setup() {
    const uuid = UniqueID();
    return {
      uuid,
    };
  },
};
</script>

<style scoped>
.input-field-label--home {
  font-weight: 600;
}

@media (max-width: 59em) {
  .input-field-label--home {
    color: #fff;
  }
}
</style>
