<template>
  <div class="table-container">
    <div class="table-menu">
      <button @click="refreshTable()" class="btn btn-main btn--refresh">
        <img src="../assets/icon-refresh.svg" alt="refresh" />
        <span>Actualizar</span>
      </button>
    </div>
    <div class="table-wrapper">
      <div v-if="isLoading" class="loading-mask">
        <div class="loading-content">
          <img src="../assets/loading-spinner.svg" alt="" />
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Frasco Código</th>
            <th>Semilla</th>
            <th>Semanas Restantes</th>
            <th>Fecha de Caducidad</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="notExistJars()">
            <td>
              <span> No se encontraron frascos proximos a vencer</span>
            </td>
          </tr>
          <tr
            v-for="jar in jars"
            :key="jar.id"
            :class="getStyeClass(jar.weeksToExpire)"
            @click="navigate(jar.id)"
          >
            <td>{{ jar.code ? jar.code : "-" }}</td>
            <td>
              {{
                jar.seed.variety.charAt(0).toUpperCase() +
                jar.seed.variety.slice(1)
              }}
            </td>
            <td>{{ getWeeksToExpireDescription(jar.weeksToExpire) }}</td>
            <td>
              {{
                new Date(jar.expirationDate).toLocaleDateString("es-CO", {
                  timeZone: "UTC",
                })
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import router from "@/router/router";

export default {
  name: "TableExpirations",
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    (async () => {
      this.isLoading = true;
      await this.$store.dispatch("jar/fetchJarsCloseToExpire");
      this.isLoading = false;
    })();
  },
  computed: {
    jars() {
      return this.$store.state.jar.jarsCloseToExpire;
    },
  },
  methods: {
    getStyeClass(weeksToExpire) {
      if (weeksToExpire < 0) {
        return "priority-expired";
      } else if (weeksToExpire <= 4) {
        return "priority-high";
      } else if (weeksToExpire <= 10) {
        return "priority-medium";
      } else {
        return "priority-low";
      }
    },
    getWeeksToExpireDescription(weeksToExpire) {
      if (weeksToExpire < 0) {
        return `Vencido hace ${-weeksToExpire} ${
          weeksToExpire == -1 ? "semana" : "semanas"
        }`;
      } else {
        return weeksToExpire;
      }
    },
    navigate(jarID) {
      router.push({
        name: "EditJar",
        params: { id: jarID },
      });
    },
    async refreshTable() {
      this.isLoading = true;
      await this.$store.dispatch("jar/refreshJarsCloseToExpire");
      this.isLoading = false;
    },
    notExistJars() {
      return this.jars.length === 0;
    },
  },
};
</script>

<style scoped>
/* Table */

.table-actions {
  display: flex;
  justify-content: flex-end;
  max-width: 160rem;
  margin: 6.4rem auto 1.8rem;
}

.table-menu {
  justify-content: flex-end;
}

.table {
  max-width: 180rem;
  margin: 15rem auto 3.6rem;
  padding: 0 10rem;
}

.table-wrapper {
  overflow-x: auto;
  position: relative;
  border-radius: 9px;
  border: 1px solid #eee;
}

table {
  width: 100%;
  height: 55%;
  font-size: 1.4rem;
  border-collapse: collapse;
  border-radius: 9px;
  overflow: hidden;
  padding-left: 2.4rem;
  justify-self: center;
  font-family: inherit;
  font-size: 1.4rem;
}

th,
td {
  border-bottom: 1px solid #fff;
  padding: 2rem;
  text-align: left;
}

thead tr {
  background-color: #fff;
  color: #333;
}

thead th {
  width: 25%;
}

tbody tr:hover {
  transition: all 0.3s;
}

.priority-expired {
  background-color: #e9ecef;
}

.priority-expired:hover {
  background-color: #dee2e6;
}

.priority-high {
  background-color: #ffc9c9;
}

.priority-high:hover {
  background-color: #ffa8a8;
}

.priority-medium {
  background-color: #fff3bf;
}

.priority-medium:hover {
  background-color: #ffec99;
}

.priority-low {
  background-color: #d3f9d8;
}

.priority-low:hover {
  background-color: #b2f2bb;
}

.loading-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #868e96a1 !important;
  display: flex;
  flex-flow: column;
  transition: opacity 0.3s ease;
}

.loading-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 59em) {
  .table {
    margin-top: 5rem;
    padding: 0 2rem;
  }
}
</style>
