import swal from "sweetalert2";

const toast = swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", swal.stopTimer);
    toast.addEventListener("mouseleave", swal.resumeTimer);
  },
});

const transformJarDates = function (jar) {
  if (jar.harvestDate) {
    jar.harvestDate = convertDateToFormat(jar.harvestDate);
  }
  if (jar.entryDate) {
    jar.entryDate = convertDateToFormat(jar.entryDate);
  }
  if (jar.expirationDate) {
    jar.expirationDate = convertDateToFormat(jar.expirationDate);
  }
};

const convertDateToFormat = function (unformattedDate) {
  const date = new Date(unformattedDate);
  // Get year, month, and day part from the date
  const year = date.toLocaleString("default", {
    year: "numeric",
    timeZone: "UTC",
  });
  const month = date.toLocaleString("default", {
    month: "2-digit",
    timeZone: "UTC",
  });
  const day = date.toLocaleString("default", {
    day: "2-digit",
    timeZone: "UTC",
  });

  // Generate yyyy-mm-dd date string
  const formattedDate = year + "-" + month + "-" + day;
  return formattedDate;
};

const getLoadingSpinner = function () {
  const loadingSpinner = `
  <style>
  .lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    animation: lds-grid 1.2s linear infinite;
  }
  .lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
  }
  .lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
  }
  @keyframes lds-grid {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }  
  </style>
  <div class="lds-grid"><div></div><div></div><div></div><div></div>
  <div></div><div></div><div></div><div></div><div></div></div>`;
  return loadingSpinner;
};

export default {
  toast,
  transformJarDates,
  convertDateToFormat,
  getLoadingSpinner,
};
