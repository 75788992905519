<template>
  <HeaderComponent showMenuOptions seeds />

  <section class="section-add-seed">
    <div class="steps-nav">
      <AddSeedNavComponentVue />
    </div>

    <h1 class="heading-primary">Cargar Semilla</h1>

    <form
      autocomplete="off"
      class="form-input-fields animate__animated animate__fadeIn animate__fast"
    >
      <BaseInput
        label="Variedad"
        type="text"
        v-model="newSeed.variety"
        tooltip="Nombre común de la semilla (ej. Maíz Blanco)"
      />

      <BaseInput
        label="Nombre Científico"
        type="text"
        v-model="newSeed.scientificName"
        tooltip="Nombre único utilizado por la comunidad científica"
      />

      <BaseSelect
        :options="categories"
        label="Categorías"
        v-model="newSeed.categories"
        tooltip="Se pueden seleccionar múltiples categorías"
      />

      <div class="add-input-field">
        <button
          class="btn btn-main btn--add-small"
          type="button"
          :onClick="addNewFamily"
        >
          +
        </button>
        <BaseSelect
          :options="families"
          label="Familia"
          v-model="newSeed.family"
          tooltip="Nombre taxonómico que agrupa al género y a la especie"
        />
      </div>

      <div class="range-field">
        <div class="input-field-label-container">
          <span class="input-field-label"
            >Rango altitudinal (mts/nivel del mar)</span
          >
          <TooltipComponent
            tooltipText="Altura medida en metros sobre el nivel del mar donde la planta puede desarrollarse"
          />
        </div>

        <div class="range-inputs">
          <BaseInput
            label="Desde"
            type="number"
            step="100"
            min="0"
            max="10000"
            v-model="newSeed.range.from"
          />
          <BaseInput
            label="Hasta"
            type="number"
            step="100"
            min="0"
            max="10000"
            v-model="newSeed.range.to"
          />
        </div>
      </div>

      <BaseSelect
        :options="pollination"
        label="Polinización"
        v-model="newSeed.pollination"
        tooltip="Autógamas se polinizan a sí mismas (trigo), 
                alógamas se polinizan de manera cruzada (maíz), 
                mixtas es una combinación de ambas (sorgo)"
      />

      <div class="page-nav-buttons">
        <router-link class="btn btn-secondary" :to="{ name: 'SeedsView' }">
          Volver
        </router-link>
        <router-link
          class="btn btn-main btn--right"
          :to="{ name: 'AddSeedStep2' }"
        >
          Continuar
        </router-link>
      </div>
    </form>
  </section>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import AddSeedNavComponentVue from "@/components/AddSeedNavComponent.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import Swal from "sweetalert2";
import TooltipComponent from "@/components/TooltipComponent.vue";

export default {
  components: {
    HeaderComponent,
    AddSeedNavComponentVue,
    BaseInput,
    BaseSelect,
    TooltipComponent,
  },
  created() {
    (async () => {
      this.$isLoading(true);
      try {
        await Promise.all([
          this.$store.dispatch("seedDetails/fetchCategories"),
          this.$store.dispatch("seedDetails/fetchFamilies"),
          this.$store.dispatch("seedDetails/fetchPollination"),
        ]);
      } catch (err) {
        console.error(err);
      }
      this.$isLoading(false);
    })();
  },
  computed: {
    newSeed() {
      return this.$store.state.seed.newSeed;
    },
    categories() {
      return this.$store.state.seedDetails.categories;
    },
    families() {
      return this.$store.state.seedDetails.families;
    },
    pollination() {
      return this.$store.state.seedDetails.pollination;
    },
  },
  methods: {
    async addNewFamily() {
      const { value: familyName } = await Swal.fire({
        title: "Nombre de familia",
        input: "text",
        inputLabel: "Ingrese el nombre de familia",
        confirmButtonColor: "#2eb086",
        cancelButtonColor: "#aaa",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Debes colocar un nombre!";
          }
        },
      });

      if (familyName) {
        const newFamily = {
          name: familyName,
        };

        this.$isLoading(true);
        try {
          await this.$store.dispatch("seedDetails/createFamily", newFamily);

          const familyNameCapitalize =
            familyName.charAt(0).toUpperCase() +
            familyName.slice(1).toLowerCase();

          this.$swal({
            title: "¡Éxito!",
            text: `Familia ${familyNameCapitalize} agregada correctamente.`,
            icon: "success",
            confirmButtonColor: "#2eb086",
          });
        } catch (err) {
          console.error(err);
          this.$swal({
            title: "¡Error!",
            text: err.response
              ? err.response.data.message
              : "Error al obtener respuesta de servicio",
            icon: "error",
            confirmButtonColor: "#2eb086",
          });
        }
        this.$isLoading(false);
      }
    },
  },
  beforeUnmount() {
    this.$store.dispatch("seed/saveNewSeedState", this.newSeed);
  },
};
</script>

<style scoped>
.section-add-seed {
  height: auto;
}

.heading-primary {
  margin-top: 4rem;
}

.range-field {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: auto;
}

.range-inputs {
  display: flex;
  gap: 2.5rem;
}
</style>
