import { apiClient } from "./DBService";

export default {
  getCategories() {
    return apiClient.get("/seedDetail/category");
  },
  getFamilies() {
    return apiClient.get("/seedDetail/family");
  },
  getPollination() {
    return apiClient.get("/seedDetail/pollination");
  },
  getGrowthForm() {
    return apiClient.get("/seedDetail/growthForm");
  },
  getGerminationMethod() {
    return apiClient.get("/seedDetail/germinationMethod");
  },
  getDispersionMethod() {
    return apiClient.get("/seedDetail/dispersionMethod");
  },
  getCropDuration() {
    return apiClient.get("/seedDetail/cropDuration");
  },
  getBioIntensiveMethod() {
    return apiClient.get("/seedDetail/bioIntensiveMethod");
  },
  getProperties() {
    return apiClient.get("/seedDetail/property");
  },
  getTypicalUses() {
    return apiClient.get("/seedDetail/typicalUse");
  },
  postFamily(family) {
    return apiClient.post("/seedDetail/family", family);
  },
  postProperty(property) {
    return apiClient.post("/seedDetail/property", property);
  },
  postTypicalUse(typicalUse) {
    return apiClient.post("/seedDetail/typicalUse", typicalUse);
  },
};
