<template>
  <HeaderComponent showMenuOptions seeds />

  <section class="section-add-seed">
    <div class="steps-nav">
      <AddSeedNavComponentVue />
    </div>

    <h1 class="heading-primary">Cargar Semilla</h1>

    <form
      autocomplete="off"
      class="form-input-fields animate__animated animate__fadeIn animate__fast"
    >
      <BaseSelect
        :options="cropDuration"
        label="Duración del cultivo"
        v-model="newSeed.cropDuration"
      />

      <div class="input-field">
        <div class="input-field-label-container">
          <label class="input-field-label">Forma de crecimiento</label>
          <TooltipComponent
            tooltipText="Relacionado a la morfología de la planta"
          />
        </div>

        <VueMultiselect
          v-model="newSeed.growthForm"
          :options="growthForm"
          :multiple="true"
          :close-on-select="true"
          placeholder="Seleccionar"
          label="name"
          track-by="name"
        />
      </div>

      <BaseSelect
        :options="bioIntensiveMethod"
        label="Método biointensivo"
        v-model="newSeed.bioIntensiveMethod"
        tooltip="Responde a cómo se relaciona con los nutrientes del suelo"
      />

      <BaseSelect
        :options="germinationMethod"
        label="Método de germinación"
        v-model="newSeed.germinationMethod"
      />

      <BaseInput
        label="Tiempo de germinación (en días)"
        type="number"
        v-model="newSeed.germinationTime"
      />

      <BaseSelect
        :options="dispersionMethod"
        label="Modo de dispersión"
        v-model="newSeed.dispersionMethod"
        tooltip="Mecanismo de dispersión de la semilla: 
                la misma planta (autocoria) el viento (anemocoria), 
                la gravedad (barocoria), agua (hidrocoria), por animal (zoocoria)."
      />

      <div class="page-nav-buttons">
        <router-link class="btn btn-secondary" :to="{ name: 'AddSeedStep1' }">
          Volver
        </router-link>
        <router-link
          class="btn btn-main btn--right"
          :to="{ name: 'AddSeedStep3' }"
        >
          Continuar
        </router-link>
      </div>
    </form>
  </section>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import AddSeedNavComponentVue from "@/components/AddSeedNavComponent.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import BaseInput from "@/components/BaseInput.vue";
import VueMultiselect from "vue-multiselect";
import TooltipComponent from "@/components/TooltipComponent.vue";

export default {
  components: {
    HeaderComponent,
    AddSeedNavComponentVue,
    BaseSelect,
    BaseInput,
    VueMultiselect,
    TooltipComponent,
  },
  created() {
    (async () => {
      this.$isLoading(true);
      try {
        await Promise.all([
          this.$store.dispatch("seedDetails/fetchGrowthForm"),
          this.$store.dispatch("seedDetails/fetchGerminationMethod"),
          this.$store.dispatch("seedDetails/fetchDispersionMethod"),
          this.$store.dispatch("seedDetails/fetchCropDuration"),
          this.$store.dispatch("seedDetails/fetchBioIntensiveMethod"),
        ]);
      } catch (err) {
        console.error(err);
      }
      this.$isLoading(false);
    })();
  },
  computed: {
    newSeed() {
      return this.$store.state.seed.newSeed;
    },
    growthForm() {
      return this.$store.state.seedDetails.growthForm;
    },
    germinationMethod() {
      return this.$store.state.seedDetails.germinationMethod;
    },
    dispersionMethod() {
      return this.$store.state.seedDetails.dispersionMethod;
    },
    cropDuration() {
      return this.$store.state.seedDetails.cropDuration;
    },
    bioIntensiveMethod() {
      return this.$store.state.seedDetails.bioIntensiveMethod;
    },
  },
  beforeUnmount() {
    this.$store.dispatch("seed/saveNewSeedState", this.newSeed);
  },
};
</script>

<style scoped>
.section-add-seed {
  height: auto;
}

.heading-primary {
  margin-top: 4rem;
}
</style>
