<template>
  <HeaderComponent showMenuOptions jars />

  <section class="section-add-jar">
    <h1 class="heading-primary">Editar Frasco</h1>
    <form
      autocomplete="off"
      @submit.prevent="editJar"
      class="form-input-fields"
    >
      <BaseInput
        label="Código"
        type="text"
        v-model="selectedJar.code"
        tooltip="Código unico e identificador de cada frasco"
      />

      <div class="input-field">
        <div class="input-field-label-container">
          <label class="input-field-label">Semilla</label>
          <TooltipComponent
            tooltipText="Seleccione la variedad de semilla que va a contener el frasco"
          />
        </div>

        <VueMultiselect
          v-model="selectedJar.seed"
          :options="seeds"
          :close-on-select="true"
          placeholder="Seleccionar"
          label="variety"
          track-by="variety"
        />
      </div>

      <BaseInput
        label="Cantidad (en gramos)"
        type="text"
        v-model="selectedJar.quantity"
      />

      <BaseInput
        label="Fecha de cosecha"
        type="date"
        v-model="selectedJar.harvestDate"
      />

      <BaseInput
        label="Fecha de ingreso"
        type="date"
        v-model="selectedJar.entryDate"
        tooltip="Fecha ingreso a Viracocha Casa de Semillas"
      />

      <BaseInput
        label="Fecha de caducidad"
        type="date"
        v-model="selectedJar.expirationDate"
        disabled
        tooltip="Valor autogenerado por el sistema según período de almacenamiento"
      />

      <BaseInput
        label="Porcentaje de germinación"
        type="number"
        min="0"
        max="100"
        v-model="selectedJar.germinationPercentages"
      />

      <BaseInput
        label="Procedencia"
        type="text"
        v-model="selectedJar.origin"
        tooltip="Lugar específico donde se cosechó la semilla (ej. Viracocha)"
      />

      <ImageComponent
        label="Foto de frasco"
        :source="selectedJar.photo?.url"
        @onSelectImage="(image) => (this.photo = image)"
        @onDeleteImage="deletePhoto"
      />

      <BaseInput
        label="Observaciones"
        type="text"
        v-model="selectedJar.remarks"
        tooltip="Otra información relevante"
      />

      <div class="page-nav-buttons">
        <router-link
          class="btn btn-small btn-secondary"
          :to="{ name: 'JarsView' }"
          title="Volver"
        >
          <img src="../../assets/buttons/btn-back.svg" alt="back" />
        </router-link>

        <button
          class="btn btn-small btn-delete"
          type="button"
          @click="deleteJar"
          title="Borrar"
        >
          <img src="../../assets/buttons/btn-delete.svg" alt="delete" />
        </button>

        <button
          class="btn btn-small btn-discard"
          type="button"
          @click="discardJar"
          title="Descartar"
        >
          <img src="../../assets/buttons/btn-discard.svg" alt="discard" />
        </button>

        <button
          class="btn btn-small btn-sow"
          type="button"
          @click="sowJar"
          title="Sembrar"
        >
          <img src="../../assets/buttons/btn-sow.svg" alt="sow" />
        </button>

        <button
          class="btn btn-small btn-main btn--right"
          type="submit"
          title="Guardar"
        >
          <img src="../../assets/buttons/btn-save.svg" alt="save" />
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import BaseInput from "@/components/BaseInput.vue";
import router from "@/router/router";
import VueMultiselect from "vue-multiselect";
import TooltipComponent from "@/components/TooltipComponent.vue";
import ImageComponent from "@/components/image/ImageComponent.vue";

export default {
  data() {
    return {
      photo: null,
    };
  },
  props: ["id"],
  components: {
    HeaderComponent,
    BaseInput,
    VueMultiselect,
    TooltipComponent,
    ImageComponent,
  },
  created() {
    (async () => {
      this.$isLoading(true);
      try {
        await Promise.all([
          this.$store.dispatch("seed/fetchSeeds"),
          this.$store.dispatch("jar/fetchJar", this.id),
        ]);
      } catch (err) {
        this.$swal({
          title: "¡Error!",
          text: err.response.data
            ? err.response.data.message
            : "Error al obtener respuesta de servicio",
          icon: "error",
          confirmButtonColor: "#2eb086",
        });
      }
      this.$isLoading(false);
    })();
  },
  computed: {
    selectedJar() {
      return this.$store.state.jar.selectedJar;
    },
    seeds() {
      const seeds = this.$store.state.seed.seeds;
      seeds.sort((a, b) => a.variety.localeCompare(b.variety));
      return seeds;
    },
  },
  methods: {
    async editJar() {
      this.$isLoading(true);
      try {
        await this.$store.dispatch("jar/updateJar", this.id);

        // Upload images if exists
        if (this.photo) {
          const formData = new FormData();
          formData.append("photo", this.photo);

          await this.$store.dispatch("jar/uploadImage", {
            id: this.id,
            formData,
          });
        }

        router.push("/jars");
      } catch (err) {
        this.$swal({
          title: "¡Error!",
          text: err.response.data
            ? err.response.data.message
            : "Error al obtener respuesta de servicio",
          icon: "error",
          confirmButtonColor: "#2eb086",
        });
      }
      this.$isLoading(false);
    },
    async deletePhoto() {
      this.$isLoading(true);

      if (this.selectedJar.photo) {
        await this.$store.dispatch("jar/deleteImage");
      }

      this.$isLoading(false);
    },
    async deleteJar() {
      const result = await this.$swal({
        title: "¿Borrar frasco?",
        text: "Una vez borrados los datos no se pueden recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff8787",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Sí, borrar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        this.$isLoading(true);
        try {
          await this.$store.dispatch("jar/deleteJar", this.id);
          router.push("/jars");
          this.$swal({
            title: "¡Borrado!",
            text: "El frasco ha sido borrado exitosamente",
            icon: "success",
            confirmButtonColor: "#2eb086",
          });
        } catch (err) {
          this.$swal({
            title: "¡Error!",
            text: err.response.data
              ? err.response.data.message
              : "Error al obtener respuesta de servicio",
            icon: "error",
            confirmButtonColor: "#2eb086",
          });
        }
        this.$isLoading(false);
      }
    },
    async discardJar() {
      const result = await this.$swal({
        title: "¿Descartar frasco?",
        text: "Estos cambios no se pueden revertir",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff8787",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Sí, descartar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        this.$isLoading(true);
        try {
          await this.$store.dispatch("jar/discardJar", this.id);
          router.push("/jars");
          this.$swal({
            title: "¡Descartado!",
            text: "El frasco ha sido descartado exitosamente",
            icon: "success",
            confirmButtonColor: "#2eb086",
          });
        } catch (err) {
          this.$swal({
            title: "¡Error!",
            text: err.response.data
              ? err.response.data.message
              : "Error al obtener respuesta de servicio",
            icon: "error",
            confirmButtonColor: "#2eb086",
          });
        }
        this.$isLoading(false);
      }
    },
    async sowJar() {
      const result = await this.$swal({
        title: "¿Sembrar frasco?",
        text: "Estos cambios no se pueden revertir",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2eb086",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Sí, sembrar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        this.$isLoading(true);
        try {
          await this.$store.dispatch("jar/sowJar", this.id);
          router.push("/jars");
          this.$swal({
            title: "¡Sembrado!",
            text: "El frasco ha sido Sembrado exitosamente",
            icon: "success",
            confirmButtonColor: "#2eb086",
          });
        } catch (err) {
          this.$swal({
            title: "¡Error!",
            text: err.response.data
              ? err.response.data.message
              : "Error al obtener respuesta de servicio",
            icon: "error",
            confirmButtonColor: "#2eb086",
          });
        }
        this.$isLoading(false);
      }
    },
  },
};
</script>

<style scoped>
.steps-nav {
  display: flex;
  align-content: center;
}
</style>
