import JarsService from "@/services/JarsService";
import Utils from "@/features/Utils";

export const namespaced = true;

export const state = {
  newJar: {
    code: "",
    seed: null,
    quantity: "",
    harvestDate: "",
    entryDate: "",
    expirationDate: "",
    germinationPercentages: "",
    origin: "",
    photo: null,
    remarks: "",
  },
  selectedJar: {},
  selectedJarHistory: {},
  jars: [],
  jarsHistory: [],
  jarsCloseToExpire: [],
  searchJarFilter: "",
  fetchedJars: false,
  fetchedJarsHistory: false,
  fetchedJarsCloseToExpire: false,
};

export const mutations = {
  SET_JARS(state, jarsDB) {
    state.jars = jarsDB;
  },
  SET_JARS_HISTORY(state, jarsHistoryDB) {
    state.jarsHistory = jarsHistoryDB;
  },
  SET_JARS_CLOSE_TO_EXPIRE(state, jarsCloseToExpireDB) {
    state.jarsCloseToExpire = jarsCloseToExpireDB;
  },
  RESET_NEW_JAR(state) {
    state.newJar = {
      code: "",
      seed: null,
      quantity: "",
      harvestDate: "",
      entryDate: "",
      expirationDate: "",
      germinationPercentages: "",
      origin: "",
      photo: null,
      remarks: "",
    };
  },
  SET_SELECTED_JAR(state, selectedJar) {
    state.selectedJar = selectedJar;
  },
  SET_SELECTED_JAR_HISTORY(state, selectedJarHistory) {
    state.selectedJarHistory = selectedJarHistory;
  },
  ADD_JAR(state, jar) {
    state.jars.push(jar);
  },
  ADD_JAR_HISTORY(state, jarDB) {
    state.jarsHistory.push(jarDB);
  },
  UPDATE_JAR(state, jarDB) {
    const foundIndex = state.jars.findIndex((jar) => jar._id === jarDB._id);
    state.jars[foundIndex] = jarDB;
    if (state.selectedJar && state.selectedJar.id === jarDB.id) {
      state.selectedJar = jarDB;
    }
  },
  DELETE_JAR(state, id) {
    const jarsFiltered = state.jars.filter((jar) => jar._id !== id);
    state.jars = jarsFiltered;
  },
  DELETE_JAR_CLOSE_TO_EXPIRE(state, id) {
    const jarsCloseToExpireFiltered = state.jarsCloseToExpire.filter(
      (jar) => jar._id !== id
    );
    state.jarsCloseToExpire = jarsCloseToExpireFiltered;
  },
  SET_SEARCH_JAR_FILTER(state, searchJarFilter) {
    state.searchJarFilter = searchJarFilter;
  },
  SET_FETCHED_JARS(state) {
    state.fetchedJars = true;
  },
  SET_FETCHED_JARS_HISTORY(state) {
    state.fetchedJarsHistory = true;
  },
  SET_FETCHED_JARS_CLOSE_TO_EXPIRE(state, wasFetched) {
    state.fetchedJarsCloseToExpire = wasFetched;
  },
};

export const actions = {
  async fetchJars({ commit }) {
    if (state.fetchedJars) {
      return state.jars;
    } else {
      const jarsDB = await JarsService.getJars();
      commit("SET_JARS", jarsDB.data.data.jars);
      commit("SET_FETCHED_JARS");
      return jarsDB.data.data.jars;
    }
  },
  async fetchJarsHistory({ commit }) {
    if (state.fetchedJarsHistory) {
      return state.jarsHistory;
    } else {
      const jarsHistoryDB = await JarsService.getJarsHistory();
      commit("SET_JARS_HISTORY", jarsHistoryDB.data.data.jars);
      commit("SET_FETCHED_JARS_HISTORY");
      return jarsHistoryDB.data.data.jars;
    }
  },
  async fetchJarsCloseToExpire({ commit }) {
    if (state.fetchedJarsCloseToExpire) {
      return state.jarsCloseToExpire;
    } else {
      const jarsCloseToExpireDB = await JarsService.getJarsCloseToExpire();
      commit("SET_JARS_CLOSE_TO_EXPIRE", jarsCloseToExpireDB.data.data.jars);
      commit("SET_FETCHED_JARS_CLOSE_TO_EXPIRE", true);
      return jarsCloseToExpireDB.data.data.jars;
    }
  },
  async refreshJarsCloseToExpire({ commit }) {
    const jarsCloseToExpireDB = await JarsService.getJarsCloseToExpire();
    commit("SET_JARS_CLOSE_TO_EXPIRE", jarsCloseToExpireDB.data.data.jars);
    return jarsCloseToExpireDB.data.data.jars;
  },
  async createJar({ commit }, jar) {
    const jarDB = await JarsService.postJar(jar);
    commit("ADD_JAR", jarDB.data.data.jar);
    commit("SET_FETCHED_JARS_CLOSE_TO_EXPIRE", false);
    return jarDB.data.data.jar;
  },
  async fetchJar({ commit, state }, id) {
    const selectedJar = state.jars.find((jar) => jar._id === id);

    if (selectedJar) {
      const selectedJarCopy = JSON.parse(JSON.stringify(selectedJar));
      Utils.transformJarDates(selectedJarCopy);
      commit("SET_SELECTED_JAR", selectedJarCopy);
    } else {
      const jarDB = await JarsService.getJar(id);
      Utils.transformJarDates(jarDB.data.data.jar);
      commit("SET_SELECTED_JAR", jarDB.data.data.jar);
    }
  },
  async fetchJarHistory({ commit, state }, id) {
    const selectedJarHistory = state.jarsHistory.find((jar) => jar._id === id);

    if (selectedJarHistory) {
      const selectedJarHistoryCopy = JSON.parse(
        JSON.stringify(selectedJarHistory)
      );
      Utils.transformJarDates(selectedJarHistoryCopy);
      commit("SET_SELECTED_JAR_HISTORY", selectedJarHistoryCopy);
    } else {
      const jarHistoryDB = await JarsService.getJar(id);
      Utils.transformJarDates(jarHistoryDB.data.data.jar);
      commit("SET_SELECTED_JAR_HISTORY", jarHistoryDB.data.data.jar);
    }
  },
  async updateJar({ commit, state }, id) {
    const jarDB = await JarsService.updateJar(id, state.selectedJar);
    commit("UPDATE_JAR", jarDB.data.data.jar);
    commit("SET_FETCHED_JARS_CLOSE_TO_EXPIRE", false);
  },
  async deleteJar({ commit }, id) {
    const jarDB = await JarsService.deleteJar(id);
    commit("DELETE_JAR", id);
    commit("DELETE_JAR_CLOSE_TO_EXPIRE", id);
    commit("ADD_JAR_HISTORY", jarDB.data.data.jar);
  },
  async discardJar({ commit }, id) {
    const jarDB = await JarsService.discardJar(id);
    commit("DELETE_JAR", id);
    commit("DELETE_JAR_CLOSE_TO_EXPIRE", id);
    commit("ADD_JAR_HISTORY", jarDB.data.data.jar);
  },
  async sowJar({ commit }, id) {
    const jarDB = await JarsService.sowJar(id);
    commit("DELETE_JAR", id);
    commit("DELETE_JAR_CLOSE_TO_EXPIRE", id);
    commit("ADD_JAR_HISTORY", jarDB.data.data.jar);
  },
  resetNewJarState({ commit }) {
    commit("RESET_NEW_JAR");
  },
  saveSearchJarFilter({ commit }, searchJarFilter) {
    commit("SET_SEARCH_JAR_FILTER", searchJarFilter);
  },
  async uploadImage({ commit }, payload) {
    const jarDB = await JarsService.uploadImage(payload.id, payload.formData);
    commit("UPDATE_JAR", jarDB.data.data.jar);
  },
  async deleteImage({ commit, state }) {
    if (state.selectedJar) {
      const jarDB = await JarsService.deleteImage(state.selectedJar.id);
      commit("UPDATE_JAR", jarDB.data.data.jar);
    }
  },
};
