<template>
  <div class="section-container animate__animated animate__fadeIn">
    <nav class="nav-bar">
      <router-link class="viracocha-logo-home" :to="{ name: 'HomeView' }">
        <span class="viracocha-logo-text">Viracocha</span>
        <img src="../assets/viracocha-logo.svg" alt="" />
      </router-link>
    </nav>

    <section class="section-home">
      <form
        @submit.prevent="login"
        class="form-login animate__animated animate__backInLeft"
        autocomplete="off"
      >
        <h1 class="heading-primary-hero">Iniciar Sesión</h1>

        <BaseInput
          v-model="credentials.email"
          label="Email"
          type="email"
          autocomplete="email"
          home
        />

        <BaseInput
          v-model="credentials.password"
          label="Contraseña"
          type="password"
          autocomplete="current-password"
          home
        />

        <div class="btns-login">
          <button class="btn btn-main" type="submit">Iniciar Sesión</button>

          <!-- <router-link class="btn btn-signup" :to="{ name: 'SignupView' }">
            <span>Regístrate</span>
            <img class="login-icon" src="../assets/login-icon.svg" alt="" />
          </router-link> -->
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import BaseInput from "@/components/BaseInput.vue";
import router from "@/router/router";
import Utils from "@/features/Utils";

export default {
  name: "HomeView",
  components: { BaseInput },
  created() {
    if (this.$store.getters["user/loggedIn"]) {
      router.push("/start");
      Utils.toast.fire({
        icon: "success",
        title: "¡Usuario logueado exitosamente!",
      });
    }
  },
  data() {
    return {
      credentials: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async login() {
      this.$isLoading(true);
      try {
        const credentials = JSON.parse(JSON.stringify(this.credentials));
        await this.$store.dispatch("user/login", credentials);
        router.push("/start");
        Utils.toast.fire({
          icon: "success",
          title: "¡Usuario logueado exitosamente!",
        });
      } catch (err) {
        this.$swal({
          title: "¡Error!",
          text: err.response.data
            ? err.response.data.message
            : "Error al obtener respuesta de servicio",
          icon: "error",
          confirmButtonColor: "#2eb086",
        });
      }
      this.$isLoading(false);
    },
  },
};
</script>

<style scoped>
.viracocha-logo-home {
  display: flex;
  gap: 0.7rem;
  width: min-content;
  padding: 3rem;
  /* align-content: center; */
}

.section-container {
  height: 100vh;
  background-image: url(../assets/home-background.jpg);
  background-size: cover;
}

.heading-primary-hero {
  color: #333;
  font-size: 3.2rem;
  align-self: center;
  padding-bottom: 2rem;
}

.section-home {
  margin-top: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-login {
  background-color: #ffffff;
  /* background-image: linear-gradient(0deg, #ffffff 0%, #eee 100%); */

  width: 40rem;
  /* height: 44rem; */
  border: 2px solid #eeeeee;
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  padding: 5.4rem;
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
}

.btns-login {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.btn-main {
  font-size: 1.5rem;
}

@media (max-width: 59em) {
  .viracocha-logo-text {
    font-size: 2.4rem;
  }

  .form-login {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .section-container {
    height: 100vh;
    background-image: url(../assets/home-background-mobile.jpg);
    background-size: cover;
  }

  .heading-primary-hero {
    color: #fff;
    align-self: start;
  }

  .section-home {
    margin-top: 6rem;
  }

  .btn-signup {
    color: #fff;
  }

  .nav-bar {
    background: transparent;
  }

  .login-icon {
    content: url("../assets/login-icon-white.svg");
  }
}
</style>
