<template>
  <div class="tooltip animate animate__bounceIn">
    <img src="../assets/info-icon.svg" alt="info" />
    <span class="tooltip-text">{{ tooltipText }}</span>
  </div>
</template>

<script>
export default {
  props: {
    tooltipText: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.tooltip {
  position: relative;
  display: flex;
  width: min-content;
  height: min-content;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 240px;
  background-color: #fff;
  color: #333;
  text-align: center;
  border-radius: 9px;
  padding: 9px;
  position: absolute;
  z-index: 1;
  bottom: 170%;
  left: 50%;
  margin-left: -120px;
  font-size: 12px;
  line-height: 17px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.19);
}

.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}
</style>
