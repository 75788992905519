import { createStore } from "vuex";

import * as seed from "@/store/modules/seed.js";
import * as jar from "@/store/modules/jar.js";
import * as user from "@/store/modules/user.js";
import * as seedDetails from "@/store/modules/seedDetails.js";

export default createStore({
  modules: {
    seed,
    jar,
    user,
    seedDetails,
  },
});
