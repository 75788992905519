<template>
  <HeaderComponent showMenuOptions history />

  <section class="section-add-jar">
    <h1 class="heading-primary">Ver Frasco</h1>
    <form
      autocomplete="off"
      @submit.prevent="editJar"
      class="form-input-fields"
    >
      <BaseInput
        label="Código"
        type="text"
        v-model="selectedJarHistory.code"
        disabled
      />
      <BaseInput
        v-if="selectedJarHistory.seed"
        label="Semilla"
        type="text"
        v-model="selectedJarHistory.seed.variety"
        disabled
      />
      <BaseInput
        label="Fecha de cosecha"
        type="date"
        v-model="selectedJarHistory.harvestDate"
        disabled
      />
      <BaseInput
        label="Fecha de ingreso"
        type="date"
        v-model="selectedJarHistory.entryDate"
        disabled
      />
      <BaseInput
        label="Fecha de caducidad"
        type="date"
        v-model="selectedJarHistory.expirationDate"
        disabled
      />
      <BaseInput
        label="Porcentaje de germinación"
        type="number"
        min="0"
        max="100"
        v-model="selectedJarHistory.germinationPercentages"
        disabled
      />
      <BaseInput
        label="Procedencia"
        type="text"
        v-model="selectedJarHistory.origin"
        disabled
      />

      <BaseInput
        label="Cantidad (en gramos)"
        type="text"
        v-model="selectedJarHistory.quantity"
        disabled
      />

      <ImageComponent
        v-if="selectedJarHistory.photo"
        label="Foto de frasco"
        :source="selectedJarHistory.photo?.url"
        :disabled="true"
      />

      <BaseInput
        label="Observaciones"
        type="text"
        v-model="selectedJarHistory.remarks"
        disabled
      />

      <div class="page-nav-buttons">
        <router-link
          class="btn btn-secondary"
          :to="{ name: 'HistoryView' }"
          title="Volver"
        >
          Volver
        </router-link>
      </div>
    </form>
  </section>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import BaseInput from "@/components/BaseInput.vue";
import router from "@/router/router";
import ImageComponent from "@/components/image/ImageComponent.vue";

export default {
  name: "JarHistoryView",
  props: ["id"],
  components: {
    HeaderComponent,
    BaseInput,
    ImageComponent,
  },
  created() {
    (async () => {
      this.$isLoading(true);
      try {
        await this.$store.dispatch("jar/fetchJarHistory", this.id);
      } catch (err) {
        this.$swal(
          "Error",
          err.response.data
            ? err.response.data.message
            : "Error al obtener respuesta de servicio",
          "error"
        );
      }
      this.$isLoading(false);
    })();
  },
  computed: {
    selectedJarHistory() {
      return this.$store.state.jar.selectedJarHistory;
    },
  },
  methods: {
    async editJar() {
      this.$isLoading(true);
      try {
        await this.$store.dispatch("jar/updateJar", this.id);
        router.push("/jars");
      } catch (err) {
        this.$swal(
          "Error",
          err.response.data
            ? err.response.data.message
            : "Error al obtener respuesta de servicio",
          "error"
        );
      }
      this.$isLoading(false);
    },
    async deleteJar() {
      const result = await this.$swal({
        title: "¿Borrar frasco?",
        text: "Una vez borrados los datos no se pueden recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff8787",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Sí, borrar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        this.$isLoading(true);
        try {
          await this.$store.dispatch("jar/deleteJar", this.id);
          router.push("/jars");
          this.$swal({
            title: "¡Borrado!",
            text: "El frasco ha sido borrado exitosamente",
            icon: "success",
            confirmButtonColor: "#2eb086",
          });
        } catch (err) {
          this.$swal(
            "Error",
            err.response.data
              ? err.response.data.message
              : "Error al obtener respuesta de servicio",
            "error"
          );
        }
        this.$isLoading(false);
      }
    },
    async discardJar() {
      const result = await this.$swal({
        title: "¿Deshechar frasco?",
        text: "Estos cambios no se pueden revertir",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff8787",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Sí, deshechar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        this.$isLoading(true);
        try {
          await this.$store.dispatch("jar/discardJar", this.id);
          router.push("/jars");
          this.$swal({
            title: "¡Descartado!",
            text: "El frasco ha sido descartado exitosamente",
            icon: "success",
            confirmButtonColor: "#2eb086",
          });
        } catch (err) {
          this.$swal(
            "Error",
            err.response.data
              ? err.response.data.message
              : "Error al obtener respuesta de servicio",
            "error"
          );
        }
        this.$isLoading(false);
      }
    },
    async sowJar() {
      const result = await this.$swal({
        title: "¿Sembrar frasco?",
        text: "Estos cambios no se pueden revertir",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2eb086",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Sí, sembrar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        this.$isLoading(true);
        try {
          await this.$store.dispatch("jar/sowJar", this.id);
          router.push("/jars");
          this.$swal({
            title: "¡Sembrado!",
            text: "El frasco ha sido Sembrado exitosamente",
            icon: "success",
            confirmButtonColor: "#2eb086",
          });
        } catch (err) {
          this.$swal(
            "Error",
            err.response.data
              ? err.response.data.message
              : "Error al obtener respuesta de servicio",
            "error"
          );
        }
        this.$isLoading(false);
      }
    },
  },
};
</script>

<style scoped>
.steps-nav {
  display: flex;
  align-content: center;
}
</style>
