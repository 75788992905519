import { apiClient } from "./DBService";

export default {
  signup(user) {
    return apiClient.post("/users/signup", user);
  },
  login(credentials) {
    return apiClient.post("/users/login", credentials);
  },
};
