import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const setHeaderToken = (token) => {
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const removeHeaderToken = () => {
  apiClient.defaults.headers.common["Authorization"] = null;
};

export { apiClient, setHeaderToken, removeHeaderToken };
