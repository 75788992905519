import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
// import SignupView from "../views/user/SignupView.vue";
import StartView from "../views/StartView.vue";
import SeedsView from "../views/seeds/SeedsView.vue";
import AddSeedStep1 from "../views/seeds/add-seed/AddSeedStep1.vue";
import AddSeedStep2 from "../views/seeds/add-seed/AddSeedStep2.vue";
import AddSeedStep3 from "../views/seeds/add-seed/AddSeedStep3.vue";
import EditSeedView from "../views/seeds/EditSeedView.vue";
import EditJarView from "../views/jars/EditJarView.vue";
import JarsView from "../views/jars/JarsView.vue";
import AddJarView from "../views/jars/AddJarView.vue";
import UserView from "../views/user/UserView.vue";
import ExpirationsView from "../views/expirations/ExpirationsView.vue";
import HistoryView from "../views/history/HistoryView.vue";
import JarHistoryView from "../views/history/JarHistoryView.vue";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
  },
  // Enable this route if we want to allow signup
  // {
  //   path: "/signup",
  //   name: "SignupView",
  //   component: SignupView,
  // },
  {
    path: "/start",
    name: "StartView",
    component: StartView,
    meta: { requiresAuth: true },
  },
  {
    path: "/seeds",
    name: "SeedsView",
    component: SeedsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/seeds/add-seed1",
    name: "AddSeedStep1",
    component: AddSeedStep1,
    meta: { requiresAuth: true },
  },
  {
    path: "/seeds/add-seed2",
    name: "AddSeedStep2",
    component: AddSeedStep2,
    meta: { requiresAuth: true },
  },
  {
    path: "/seeds/add-seed3",
    name: "AddSeedStep3",
    component: AddSeedStep3,
    meta: { requiresAuth: true },
  },
  {
    path: "/seeds/:id",
    name: "EditSeed",
    props: true,
    component: EditSeedView,
    meta: { requiresAuth: true },
  },
  {
    path: "/jars",
    name: "JarsView",
    component: JarsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/jars/add-jar",
    name: "AddJarView",
    component: AddJarView,
    meta: { requiresAuth: true },
  },
  {
    path: "/jars/:id",
    name: "EditJar",
    props: true,
    component: EditJarView,
    meta: { requiresAuth: true },
  },
  {
    path: "/expirations",
    name: "ExpirationsView",
    component: ExpirationsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/history",
    name: "HistoryView",
    component: HistoryView,
    meta: { requiresAuth: true },
  },
  {
    path: "/history/:id",
    name: "JarHistoryView",
    props: true,
    component: JarHistoryView,
    meta: { requiresAuth: true },
  },
  {
    path: "/user",
    name: "UserView",
    component: UserView,
    meta: { requiresAuth: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "HomeView",
    component: HomeView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("userData");
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next("/");
    return;
  }
  next();
});

export default router;
