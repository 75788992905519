<template>
  <div class="nav-steps">
    <router-link class="step" :to="{ name: 'AddSeedStep1' }">
      Paso 1
    </router-link>
    <router-link class="step" :to="{ name: 'AddSeedStep2' }">
      Paso 2
    </router-link>
    <router-link class="step" :to="{ name: 'AddSeedStep3' }">
      Paso 3
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.nav-steps {
  display: flex;
  gap: 2rem;
}

.step {
  padding: 1rem;
  color: #bec0c0;
  font-weight: 700;
  font-size: 1.4rem;
  border-bottom: 3px solid;
}

.step.router-link-active {
  color: #2eb086;
}
</style>
